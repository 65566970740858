import React, { useContext, useState } from "react";
import { ThemeContext, PreferredLanguageContext } from "../../lib/main-context";
import { EXPORT_PDF, OFF_DARK_MODE_EXPORT } from "../../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { parse } from "date-fns";

import theme from "../../index.scss";
import SubmitButton from "../StyledComponents/SubmitButton";
import ModalPdf from "./ModalPdf";
import options from "./LineChartOptions";

import fillInDates from "../../lib/fillInDates";
import getDaysAgo from "../../lib/getDaysAgo";
import triggerWithSetVal from "../../lib/triggerWithSetVal";

const LineChart = ({ topSymptoms, data, additionalNotes }) => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const { dark } = useContext(ThemeContext);
  const { language } = useContext(PreferredLanguageContext);

  const colors = [
    theme.chart1,
    theme.chart2,
    theme.chart3,
    theme.chart4,
    theme.chart5,
    theme.chart6,
    theme.chart7,
    theme.chart8,
    theme.chart9,
    theme.chart10,
  ];
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let datasetArr = [];
  topSymptoms.forEach((symptom) => {
    let dataset = data?.filter(({ factor }) => factor === symptom.factor);
    datasetArr.push(dataset);
  });
  let triggerList = triggerWithSetVal(data);
  datasetArr.push(triggerList);

  const mapData = (dataset) => {
    return (
      dataset?.map((item) => {
        const date = item.symptom_date.slice(0, 10);
        return {
          // CURRENT BUG, Must remove time stamp or labels do not work correctly
          x: date,
          y: item.severity,
          desc: item.description,
        };
      })
        // needs to return > 8 days ago because the times will never match perfectly
        .filter(
          (item) => parse(item.x, "yyyy-MM-dd", new Date()) > getDaysAgo(7)
        )
    );
  };

  const newData = {
    datasets: [],
  };
  let { datasets } = newData;
  datasetArr.forEach((dataset, index) => {
    if (index < datasetArr.length - 1) {
      datasets.push({
        fill: false,
        label: topSymptoms[index].factor,
        backgroundColor: colors[index],
        borderColor: colors[index],
        borderWidth: 1,
        hoverBackgroundColor: colors[index],
        hoverBorderColor: "white",
        pointBorderWidth: 4,
        data: mapData(dataset),
      });
    } else {
      datasets.push({
        label: "Trigger",
        data: mapData(dataset),
        pointStyle: "triangle",
        type: "scatter",
        backgroundColor: theme.primary,
        borderWidth: 1,
      });
    }
  });

  datasets = fillInDates(datasets, 7);
  newData.notes = additionalNotes;

  const handleClick = (e) => {
    try {
      // set the selected symptom to the index of the clicked dataset
      // eslint-disable-next-line no-underscore-dangle
      const symptomIndex = e[0]._datasetIndex;
      history.push(
        `/charts/${encodeURIComponent(topSymptoms[symptomIndex].factor)}`
      );
    } catch {
      // if there is an error, it's because the user did not click on
      // an element of the chart
    }
  };

  options.scales.xAxes[0].ticks = {
    fontColor: dark ? theme.darkModeText : "#666",
  };
  options.scales.yAxes[0].ticks = {
    beginAtZero: true,
    fontColor: dark ? theme.darkModeText : "#666",
  };

  return (
    <>
      <div className="lineChart">
        <Line
          getDatasetAtEvent={handleClick}
          data={newData}
          width={100}
          height={300}
          options={{
            ...options,
            maintainAspectRatio: false,
            title: {
              display: true,
              text: "Week in Review",
              fontFamily: "quicksand",
              fontStyle: "bold",
              fontColor: dark ? theme.darkModeText : "#666",
            },
            legend: {
              labels: {
                fontColor: dark ? theme.darkModeText : "#666",
                usePointStyle: true,
                boxWidth: 8,
              },
            },
          }}
        />
      </div>
      <SubmitButton
        onClick={() => {
          if (dark) {
            alert(OFF_DARK_MODE_EXPORT[language]);
          } else {
            handleShow();
          }
        }}
      >
        {EXPORT_PDF[language]}
      </SubmitButton>
      <ModalPdf
        show={show}
        handleClose={handleClose}
        chart="lineChart"
        data={data}
        additionalNotes={additionalNotes}
        days={7}
      />
    </>
  );
};

export default LineChart;
