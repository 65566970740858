import { jsPDF } from "jspdf";
import "jspdf-autotable";
import getDaysAgo from "./getDaysAgo";
import { parse, format } from "date-fns";
import logo from "../images/Logo.png";
import { getGoals } from "../api/TBIRequests";

// This function is for creating pdf file using jsPDF.
// Currently we include a chart as a image and all the symptom notes in a table format based on selected chart.
// For more info about jsPDF, please visit jsPDF documentation: http://raw.githack.com/MrRio/jsPDF/master/docs/index.html

const exportToPdf = async (
  chart,
  data,
  days,
  additionalNotes,
  patient,
  withoutNote
) => {
  // input is the html item <div class="barChart"></div> for selected chart
  let input = window.document.getElementsByClassName(chart)[0].lastChild;
  // getting goal list for the current patient
  let goals = undefined;
  await getGoals(patient.patientId)
    .then((result) => {
      goals = result.data[0];
    })
    .catch((err) => console.log(err));

  // getting symptom data based on selected table (3 day, 7 day, or 30 day)
  // if the withoutNote option is true, omit the symptom description.
  const dataForDays = data
    .filter((item) => new Date(item.symptom_date) > getDaysAgo(days))
    .map((item) => {
      return {
        ...item,
        date: format(new Date(item.symptom_date), "MM-dd-yyyy"),
        description: withoutNote ? null : item.description,
      };
    });

  let tableBody = dataForDays.slice();

  if (!withoutNote) {
    // this if block for appending additional notes under each date's symptom data.
    // if withoutNote is true, omit additional notes.
    dataForDays.forEach((data, i) => {
      let tempDate = data.symptom_date;
      if (
        i === dataForDays.length - 1 ||
        tempDate !== dataForDays[i + 1].symptom_date
      ) {
        let notesForTempDate = additionalNotes.filter(
          (item) => item.additional_notes_date === tempDate
        );
        notesForTempDate.forEach((item) => {
          const additionalNoteDate = format(
            new Date(item.additional_notes_date),
            "MM-dd-yyyy"
          );
          let transformedNote = {
            date: additionalNoteDate,
            factor: "Additional Comments for " + additionalNoteDate,
            severity: null,
            description: item.note,
          };
          tableBody.splice(
            i + 1 + tableBody.length - dataForDays.length,
            0,
            transformedNote
          );
        });
      }
    });
  }

  // Y index where chart will be placed
  let chartStartY = 125;

  // converting html to png type
  const img = input.toDataURL("image/png");
  // initiating pdf file
  const pdf = new jsPDF("l", "pt");

  const pageWidth = pdf.internal.pageSize.getWidth();
  let chartWidth = input.clientWidth * 0.7;
  let chartHeight = input.clientHeight * 0.7;

  pdf.setFont("helvetica", "bold");
  pdf.setTextColor(33, 33, 33);
  pdf.setFontSize(12);
  pdf.text("GOALS", 40, 80);
  pdf.setFont("helvetica", "normal");

  if (goals) {
    let firstGoal = ["1. " + goals.first_goal];
    let secondGoal = ["2. " + goals.second_goal];
    let thirdGoal = ["3. " + goals.third_goal];

    // If each goal text length is longer than pdf width, seperating into lines so it fits to the page.
    if (firstGoal[0].length > 150) {
      let lines = pdf.splitTextToSize(firstGoal[0], 765);
      firstGoal = lines;
    }
    if (secondGoal[0].length > 150) {
      let lines = pdf.splitTextToSize(secondGoal[0], 765);
      secondGoal = lines;
    }
    if (thirdGoal[0].length > 150) {
      let lines = pdf.splitTextToSize(thirdGoal[0], 765);
      thirdGoal = lines;
    }

    let goalText = firstGoal.concat(secondGoal, thirdGoal);

    if (goalText.length > 3) {
      // as goal section is getting larger, the Y index for chart needs to be adjusted.
      chartStartY += (goalText.length - 3) * 15;
    }

    pdf.text(goalText, 60, 100);
  }

  //detail table config
  const dataWithDesc = {
    columns: [
      { header: "Date", dataKey: "date" },
      { header: "Symptom", dataKey: "factor" },
      { header: "Severity", dataKey: "severity" },
      { header: "Description", dataKey: "description" },
    ],
    body: tableBody,
    startY: chartHeight + chartStartY + 30,
    margin: { top: 140 },
  };

  //adding chart
  pdf.addImage(
    img,
    "png",
    (pageWidth - chartWidth) / 2,
    chartStartY,
    chartWidth,
    chartHeight
  );

  //adding table
  pdf.setFont("helvetica", "bold");
  pdf.text("DETAILS", 40, chartHeight + chartStartY + 20);
  pdf.autoTable(dataWithDesc);

  //header and footer for each page
  var totalPage = pdf.internal.getNumberOfPages();
  for (var i = 1; i <= totalPage; i++) {
    pdf.setPage(i);
    pdf.addImage(logo, "png", 710, 10, 120, 50);

    pdf.setFillColor(61, 37, 102);
    pdf.rect(30, 60, 800, 3, "F");
    pdf.setFontSize(17);

    //header
    pdf.text(
      "Summary report for " +
      format(getDaysAgo(days - 1), "MMM dd") +
      " - " +
      format(new Date(), "MMM dd, yyyy"),
      30,
      40
    );

    //footer
    pdf.setFontSize(12);
    pdf.textWithLink("powerofpatients.com", 40, 570, {
      url: "https://www.powerofpatients.com",
    });
    pdf.text("page: " + i + " / " + totalPage, 750, 570);
  }

  // saving pdf to local machine
  pdf.save(`${chart}.pdf`);
};

export default exportToPdf;
