import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import {
  STROKE_HEADER2,
  STROKE_SALLIE2,
  STROKE_TYPE,
  STROKE_PRIOR,
  STROKE_RISKS,
  CHOOSE,
  SAVE_AND_CONTINUE
} from "../../constants/OnboardingTranslation";

// hooks
import { useHistory, useLocation } from "react-router-dom";
import useListSelector from "../../lib/useListSelector";

// components
import Sallie from "../Sallie";
import StrokeListSelecter from "../StrokeListSelecter";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";

// api
import { updateStrokeDetail } from "../../api/TBIRequests";

// lib
import customizeMessages from "../../lib/customizeMessages";
import { Form } from "react-bootstrap";

const RecentStroke2 = ({ setOnboardingPercent, user }) => {
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [returnedData, setReturnedData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const categories = [
    { category: "typeStroke", items: STROKE_TYPE[language].options },
    { category: "priorStroke", items: STROKE_PRIOR[language].options },
    { category: "riskFactors", items: STROKE_RISKS[language].options }
  ];

  const strokeInfo = categories.map(({ category, items }) => ({ category, items }));

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });
  const onSubmit = async () => {
    // join lists into strings for back end
    const assignedData = {
      typeStroke: selectedData.typeStroke[0], // it's in an array but only has one item, so we just take that item
      priorStroke: selectedData.priorStroke,
      riskFactors: selectedData.riskFactors,
    };
    if (!assignedData.typeStroke || assignedData.priorStroke.length === 0 || assignedData.riskFactors.length === 0) {
      setErrorMessage(true);
      return;
    }
    setSubmitting(true);
    try {
      const tbiDetailRes = await updateStrokeDetail({
        patientId: patient.patientId,
        data: assignedData,
      });
      history.push("/laterSymptoms", {
        date: tbiDetailRes.data.slice(0, tbiDetailRes.data.indexOf("T")),
        fromPatientControl: location.state?.fromPatientControl,
      });
    } catch (err) {
      history.push("/oops");
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    location.state?.fromPatientControl
      ? setOnboardingPercent(40)
      : setOnboardingPercent(30);
    setReturnedData(strokeInfo);
    initialize(strokeInfo);
    setLoading(false);
  }, [language]);

  const getPossibleMessages = () => ({
    mainHeader: {
      Stroke: STROKE_HEADER2[language],
      caregiver: STROKE_HEADER2[language],
    },
    sallieText: {
      Stroke: STROKE_SALLIE2[language].patient,
      caregiver: STROKE_SALLIE2[language].caregiver,
    },
    typeStroke: {
      Stroke: STROKE_TYPE[language].patient,
      caregiver: STROKE_TYPE[language].caregiver,
    },
    priorStroke: {
      Stroke: STROKE_PRIOR[language].patient,
      caregiver: STROKE_PRIOR[language].caregiver,
    },
    riskFactors: {
      Stroke: STROKE_RISKS[language].patient,
      caregiver: STROKE_RISKS[language].caregiver,
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  // The keys here must match the categories specified on the backend
  const options = {
    typeStroke: {
      message: "typeStroke",
      responseNumber: 1,
    },
    priorStroke: {
      message: "priorStroke",
    },
    riskFactors: {
      message: "riskFactors",
    },
  };


  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <div className="d-block">
          <OnboardingStepHeader text={getMessage("mainHeader")} />
          <Sallie text={getMessage("sallieText")} />

          {returnedData.map((itemList, i) => {
            const { category } = itemList;
            return (
              <StrokeListSelecter
                key={category}
                category={category}
                message={getMessage(options[category].message)}
                list={itemList.items}
                index={i}
                maxSelectAmount={options[category].responseNumber}
                selectedList={selectedData[category]}
                toggleFromSelectedList={toggleFromSelectedList}
                selectOne={switchSingleSelection}
                addOther={addOther}
              />
            );
          })}
          {errorMessage && (
            <Form.Control.Feedback
              style={{ display: "block" }}
              type="invalid"
            >
              {CHOOSE[language].error}
            </Form.Control.Feedback>
          )}
          <SubmitButton disabled={submitting} onClick={onSubmit} type="submit">
            {SAVE_AND_CONTINUE[language]} &gt;
          </SubmitButton>
        </div>
      )}
    </>
  );
};

export default RecentStroke2;
