import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { Form, Col, Row } from "react-bootstrap";
import {
    CONDITION_HEADER,
    SALLIE_CONDITION,
    CONDITION_DATE,
    CONDITION_DESCRIPTION,
    CONDITION_PRIOR_TBI,
    SAVE_AND_CONTINUE
} from "../../constants/OnboardingTranslation";

// hooks
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";

// components
import Sallie from "../Sallie";
import LabelledInput from "../LabelledInput";
import DescribeCondition from "./validations/describeCondition";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";

// api
import { createCondition } from "../../api/TBIRequests";
import customizeMessages from "../../lib/customizeMessages";

const RecentCondition1 = ({ user, setOnboardingPercent }) => {
    const { patient } = useContext(PatientContext);
    const { language } = useContext(PreferredLanguageContext);

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const { register, handleSubmit, errors, watch } = useForm({
        resolver: yupResolver(DescribeCondition),
    });
    useEffect(() => {
        location.state?.fromPatientControl
            ? setOnboardingPercent(30)
            : setOnboardingPercent(20);
        setLoading(false);
    }, []);

    const onSubmit = async (data) => {
        setSubmitting(true);
        try {
            data.dateOfSymptoms = format(new Date(data.dateOfSymptoms), "MM-dd-yyyy");
            await createCondition({ patientId: patient.patientId, data });
            setSubmitting(false);
            history.push("/RecentCondition2", {
                fromPatientControl: location.state?.fromPatientControl,
            });
        } catch (err) {
            history.push("/oops");
        }
    };

    const getPossibleMessages = ({ getPronoun }) => ({
        mainHeader: {
            tbiPatient: CONDITION_HEADER[language].patient,
            caregiver: CONDITION_HEADER[language].caregiver,
        },
        sallieText: {
            tbiPatient: SALLIE_CONDITION[language].patient,
            caregiver: SALLIE_CONDITION[language].caregiver,
        },
        dateOfTbiLabel: {
            tbiPatient: CONDITION_DATE[language].patient,
            caregiver: CONDITION_DATE[language].caregiver,
        },
        addTbis: {
            tbiPatient:
                "You will have the option to add other " +
                "TBI&apos;s after you set up your account.",
            caregiver:
                "You will have the option to add other " +
                "TBI&apos;s after you set up your account.",
        },
        describeEventLabel: {
            tbiPatient: CONDITION_DESCRIPTION[language].patient.label,
            caregiver: CONDITION_DESCRIPTION[language].caregiver.label,
        },
        describeEventExtraInfo: {
            tbiPatient: CONDITION_DESCRIPTION[language].patient.info,
            caregiver: CONDITION_DESCRIPTION[language].patient.info,
        },
        describeEventPlaceholder: {
            tbiPatient: CONDITION_DESCRIPTION[language].patient.placeholder,
            caregiver: CONDITION_DESCRIPTION[language].caregiver.placeholder
        },
        totalTbiLabel: {
            tbiPatient: CONDITION_PRIOR_TBI[language].patient,
            caregiver: CONDITION_PRIOR_TBI[language].caregiver,
        },
    });

    const getMessage = customizeMessages({ user, getPossibleMessages });

    return (
        <>
            {loading ? (
                "Loading..."
            ) : (
                <>
                    <div className="d-block">
                        <OnboardingStepHeader text={getMessage("mainHeader")} />

                        <Sallie text={getMessage("sallieText")} />
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="d-block">
                            <Form.Row>
                                <Col>
                                    <LabelledInput
                                        autoFocus
                                        required
                                        labelAs="h6"
                                        name="dateOfSymptoms"
                                        placeholder="MM/DD/YYYY"
                                        type="date"
                                        date-format="mm/dd/yyyy"
                                        inputRef={register}
                                        label={getMessage("dateOfTbiLabel")}
                                        errors={errors}
                                    />
                                </Col>
                                {/* hidden until we can implement, change to block when done */}
                                <Col className="d-none align-items-end">
                                    <div className="mb-3 ml-3">{getMessage("addTbis")}</div>
                                </Col>
                                {/* end hidden */}
                            </Form.Row>
                            <LabelledInput
                                inputAs="textarea"
                                required
                                labelAs="h6"
                                extraInfo={getMessage("describeEventExtraInfo")}
                                name="describeSymptoms"
                                placeholder={getMessage("describeEventPlaceholder")}
                                type="text"
                                inputRef={register}
                                label={getMessage("describeEventLabel")}
                                errors={errors}
                                rows={3}
                            />
                            <Form.Group>
                                <Form.Label className={'h6-label'} >
                                    {getMessage("totalTbiLabel")}
                                    <span className="required">*</span>
                                </Form.Label>
                                <div>
                                    <Form.Check
                                        inline
                                        label={CONDITION_PRIOR_TBI[language].yes}
                                        value="Yes"
                                        name="hasTbiBefore"
                                        type="radio"
                                        id="tbiYes"
                                        ref={register}
                                    />
                                    <Form.Check
                                        inline
                                        label={CONDITION_PRIOR_TBI[language].no}
                                        value="No, I do not recall"
                                        name="hasTbiBefore"
                                        type="radio"
                                        id="tbiNo"
                                        ref={register}
                                        className="ml-3"
                                    />
                                </div>
                                {errors.hasTbiBefore && (
                                    <Form.Control.Feedback style={{ display: "block" }} type="invalid">
                                        {errors.hasTbiBefore.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Row>
                                <SubmitButton type="submit" disabled={submitting}>
                                    {SAVE_AND_CONTINUE[language]} &gt;
                                </SubmitButton>
                            </Row>
                        </div>
                    </Form>
                </>
            )
            }
        </>
    );
};

export default RecentCondition1;
