import React, { useState, useEffect, useContext } from "react";

// Hooks
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

// Bootstrap
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";

// Validation Rules
import SignupSchema from "./validations/registerValidation";
import {
  registerUser,
  registerPatient,
  connectInvitedPatientWithTP,
  connectInvitedPatientWithP,
  connectInvitedTPWithPatient,
  connectInvitedTPWithCaregiver,
} from "../../api/auth";
import {
  getProviderTokenInformation,
  getCaregiverTherapistId,
  getProviderID,
  getPatientID,
  getPatientIDForCgInviteTP,
  sendConfirmEmail,
  checkExistingList,
} from "../../api/helpReq";
import {
  createGoals,
  getPatientTypes,
  getProviderTypes,
  getReferralGroups,
} from "../../api/TBIRequests";

// Components
import Sallie from "../Sallie";
import PrivacyPage from "../PrivacyPage";
import PrivacyPagePatient from "../PrivacyPagePatient";
import LinkButton from "../StyledComponents/LinkButton";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";
import CountryList from "../../api/countryList.json";
import Dropdown from "react-multilevel-dropdown";
import referralList from "./ReferralList";
import PolicyComponent from "./PolicyComponent";
import "./basicinfo.scss";
import SelectField from "../SelectField";
import useListSelector from "../../lib/useListSelector";
import StrokeListSelecter from "../StrokeListSelecter";

// Translation
import { PreferredLanguageContext } from '../../lib/main-context'
import {
  STEP_1A,
  BASIC_INFORMATION,
  UNDER_13,
  REGISTRATION_TYPE,
  PRIMARY_DIAGNOSIS,
  PROVIDER_TYPE,
  NAME,
  RACE,
  ETHNICITY,
  VETERAN,
  GENDER,
  PHONE,
  EMAIL,
  DOB,
  COUNTRY,
  ZIPCODE,
  PASSWORD,
  MESSAGES_AND_ALERTS,
  TERMS_AND_CONDITIONS,
  SAVE_AND_CONTINUE,
} from '../../constants/OnboardingTranslation'

const BasicInfo = ({
  setOnboardingPercent,
  setUser,
  setPatient,
  setPatientList,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [viewPolicy, setViewPolicy] = useState(false);
  const [viewPolicyPatient, setViewPolicyPatient] = useState(false);
  const [alertPolicy, setAlertPolicy] = useState(true);
  const [messagePolicy, setMessagePolicy] = useState(true);
  const [patientTypes, setPatientTypes] = useState(null);
  const [referralGroups, setreferralGroups] = useState(null);
  const [referralValue, setReferralValue] = useState("none");
  const [selectReferral, setSelectReferral] = useState("none");
  const [providerEmail, setProviderEmail] = useState(null);
  const [providerTypes, setProviderTypes] = useState(null);
  const [careMId, setCareMId] = useState(null);
  const [providerId, setProviderId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [readAlert, setReadAlert] = useState(false);
  const [patientIdForPatientInviteTP, setPatientIdForPatientInviteTP] =
    useState(null);
  const [patientIdForCGInviteTP, setPatientIdForCGInviteTP] = useState(null);
  const [cgIdForCGInviteTP, setCGIdForCGInviteTP] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [registrationType, setRegistrationType] = useState("");
  // If we choose to implement otherProviderTypeValue
  // const [providerTypeValue, setProviderTypeValue] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const url = new URLSearchParams(location.search);
  const token = url.get("token");
  const isInvitedTPByCaregiver = token && token.startsWith("invitedTPByCG");
  const isInvitedTPByPatient = token && token.startsWith("invitedTPByPatient");
  const isProviderForm =
    token &&
    !token.startsWith("invitedPatient") &&
    !token.startsWith("invitedTP");
  const isInvitedPatientByTP = token && token.startsWith("invitedPatientTP");
  const isInvitedPatientByProvider =
    token && token.startsWith("invitedPatientP");
  const { register, handleSubmit, errors, setError, setValue } = useForm({
    resolver: yupResolver(SignupSchema({ isProviderForm })),
  });

  const { language } = useContext(PreferredLanguageContext);

  useEffect(() => {
    setOnboardingPercent(10);
    if (location.search.length > 0) {
      setValue("email", url.get("email"));
      setValue("firstName", url.get("first"));
      setValue("lastName", url.get("last"));
      setReferralValue(url.get("referral"));
      if (isProviderForm) {
        const data = { token };
        getProviderTokenInformation({ data })
          .then((res) => {
            const { data } = res;
            // setValue("email", data.providerEmail);
            // Assuming we are hiding the email field for providers in the JSX
            setProviderEmail(data.providerEmail);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      } else if (isInvitedPatientByTP) {
        const data = { token };
        getCaregiverTherapistId({ data })
          .then((res) => {
            const { data } = res;
            setCareMId(data.caregiver_therapist_id);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      } else if (isInvitedPatientByProvider) {
        const data = { token };
        getProviderID({ data })
          .then((res) => {
            const { data } = res;
            setProviderId(data.provider_id);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      } else if (isInvitedTPByPatient) {
        const data = { token };
        getPatientID({ data })
          .then((res) => {
            const { data } = res;
            setPatientIdForPatientInviteTP(data.patient_id);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      } else if (isInvitedTPByCaregiver) {
        const data = { token };
        getPatientIDForCgInviteTP({ data })
          .then((res) => {
            const { data } = res;
            setPatientIdForCGInviteTP(data.patient_id);
            setCGIdForCGInviteTP(data.caregiver_id);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      }
    }
  }, []);

  const handleRegistrationTypeChange = (event) => {
    setRegistrationType(event.target.value);
  };
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const types = [
    {
      category: "TBI/Concussion",
      items: PRIMARY_DIAGNOSIS[language]["TBI/Concussion"].items,
    },
    {
      category: "Dementia",
      items: PRIMARY_DIAGNOSIS[language].Dementia.items,
    },
    {
      category: "Stroke",
      items: PRIMARY_DIAGNOSIS[language].Stroke.items,
    },
    {
      category: "Other",
      items: PRIMARY_DIAGNOSIS[language].Other.items,
    },
  ];

  const [returnedData, setReturnedData] = useState([]);

  const {
    toggleFromSelectedList,
    selectForTherapist,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  useEffect(() => {
    setReturnedData(types);
    initialize(types);
  }, [language]);

  useEffect(() => {
    setOnboardingPercent(10);
    if (location.search.length > 0) {
      setValue("email", url.get("email"));
      setValue("firstName", url.get("first"));
      setValue("lastName", url.get("last"));
      setReferralValue(url.get("referral"));
      if (isProviderForm) {
        const data = { token };
        getProviderTokenInformation({ data })
          .then((res) => {
            const { data } = res;
            // setValue("email", data.providerEmail);
            // Assuming we are hiding the email field for providers in the JSX
            setProviderEmail(data.providerEmail);
          })
          .catch((err) => {
            console.error(err);
            history.push("/oops");
          });
      }
    }
  }, []);

  useEffect(() => {
    basicInfoFormFill();
  }, []);

  const basicInfoFormFill = async () => {
    try {
      // if (!isProviderForm) {
      //   getPatientTypes().then((res) => {
      //     const { data } = res;
      //     data.push({ value: "otherType", message: "Other" })
      //     setPatientTypes(data);
      //   });
      // }
      getProviderTypes().then((res) => {
        const { data } = res;
        setProviderTypes(data);
      });

      getReferralGroups().then((res) => {
        const { data } = res;
        setreferralGroups(data);
      });
    } catch {
      history.push("/oops");
    }
  };

  const onSubmit = async (data) => {
    if (!agreed) {
      setReadAlert(true);
      return;
    }
    let patientType;
    let patientSubType;
    if (data.userType == "patient") {
      if (
        selectedData[PRIMARY_DIAGNOSIS[language]["TBI/Concussion"].category] &&
        selectedData[PRIMARY_DIAGNOSIS[language]["TBI/Concussion"].category].length === 0 &&
        selectedData[PRIMARY_DIAGNOSIS[language].Dementia.category].length === 0 &&
        selectedData[PRIMARY_DIAGNOSIS[language].Stroke.category].length === 0 &&
        selectedData[PRIMARY_DIAGNOSIS[language].Other.category].length === 0
      ) {
        setErrorMessage(true);
        return;
      }
      patientType = Object.keys(selectedData)[0];
      patientSubType = Object.values(selectedData)[0][0];
      data.patientType = patientType;
      data.patientSubType = patientSubType;
    }
    if (providerEmail) {
      data.email = providerEmail;
    }
    if (isProviderForm) {
      if (token.substring(0, 9) == "caregiver") {
        data.referral = "caregiver";
      } else if (token.substring(0, 7) == "patient") {
        data.referral = "patient";
      } else {
        data.referral = "Therapist";
      }
      data.userType = "provider";
    }

    try {
      setIsLoading(true);
      if (data.userType === "provider" || isProviderForm) {
        data.providerToken = token;
        data.messagePolicy = messagePolicy;
        data.alertPolicy = alertPolicy;
      }

      if (data.userType === "Therapist") {
        data.messagePolicy = messagePolicy;
        data.alertPolicy = alertPolicy;
      }

      if (selectReferral) {
        data.referral = selectReferral;
      }

      data.email = data.email.toLowerCase();

      const registerUserResponse = await registerUser(data);
      const responseUserData = registerUserResponse.data;
      setUser({ ...responseUserData, patientSubType, patientType });
      const { firstName, lastName, email, userType, userId } = responseUserData;
      await sendConfirmEmail({
        email: email,
        name: firstName + " " + lastName,
      });
      await checkExistingList({
        email: email,
      });

      if (userType === "caregiver") {
        history.push("/RegisterPatient", {
          fromPatientControl: false,
        });
      } else if (userType === "Therapist") {
        if (isInvitedTPByPatient) {
          await connectInvitedTPWithPatient({
            patientId: patientIdForPatientInviteTP,
            userId: userId,
          });
        } else if (isInvitedTPByCaregiver) {
          await connectInvitedTPWithCaregiver({
            caregiverId: cgIdForCGInviteTP,
            careMId: userId,
            patientId: patientIdForCGInviteTP,
          });
        }
        history.push("/notificationPrefs", {
          fromPatientControl: false,
          // patientIdForPatientInviteTP,
        });
      } else if (userType == "patient" && patientType === "Stroke") {
        data.relationshipType = "self";
        // data.status = combinedValues;
        const registerPatientResponse = await registerPatient(data);
        const responsePatientData = registerPatientResponse.data;
        setPatient(responsePatientData);
        await createGoals({ patientId: responsePatientData.patientId });
        if (isInvitedPatientByTP) {
          await connectInvitedPatientWithTP({
            patientId: responsePatientData.patientId,
            careMId: careMId,
          });
        } else if (isInvitedPatientByProvider) {
          await connectInvitedPatientWithP({
            patientId: responsePatientData.patientId,
            userId: userId,
            providerId: providerId,
          });
        }
        history.push("/RecentStroke1", {
          fromPatientControl: false,
        });
      }
      // userType == "Concussion" || userType == "hasSymptom" || userType == "Post-Concussive Syndrome" || userType == "tbiPatient"
      else if (userType == "patient" && patientType == "TBI/Concussion") {
        data.relationshipType = "self";
        // data.status = combinedValues;
        const registerPatientResponse = await registerPatient(data);
        const responsePatientData = registerPatientResponse.data;
        setPatient(responsePatientData);
        await createGoals({ patientId: responsePatientData.patientId });
        if (isInvitedPatientByTP) {
          await connectInvitedPatientWithTP({
            patientId: responsePatientData.patientId,
            careMId: careMId,
          });
        } else if (isInvitedPatientByProvider) {
          await connectInvitedPatientWithP({
            patientId: responsePatientData.patientId,
            userId: userId,
            providerId: providerId,
          });
        }
        history.push("/RecentTBI1", {
          fromPatientControl: false,
        });
      } else if (
        (userType == "patient" && patientType == "Dementia") ||
        patientType == "Other"
      ) {
        // For those are not tbi patients
        data.relationshipType = "self";
        // data.status = combinedValues;
        const registerPatientResponse = await registerPatient(data);
        const responsePatientData = registerPatientResponse.data;
        setPatient(responsePatientData);
        await createGoals({ patientId: responsePatientData.patientId });
        if (isInvitedPatientByTP) {
          await connectInvitedPatientWithTP({
            patientId: responsePatientData.patientId,
            careMId: careMId,
          });
        } else if (isInvitedPatientByProvider) {
          await connectInvitedPatientWithP({
            patientId: responsePatientData.patientId,
            userId: userId,
            providerId: providerId,
          });
        }
        history.push("/RecentCondition1", {
          fromPatientControl: false,
        });
      } else {
        //provider
        history.push("/PaymentPage");
      }
    } catch (err) {
      console.log(err);
      if (err.message && err.message.includes("409")) {
        setError("email", {
          type: "emailTaken",
          message: "Email is already taken!",
        });
      } else if (err.message && err.message.includes("406")) {
        setError("email", {
          type: "emailNotValid",
          message: "This is not valid email.",
        });
      } else {
        history.push("/oops");
      }
    } finally {
      setIsLoading(false);
    }
  };

  let SallieMsg = BASIC_INFORMATION[language];

  if (
    errors.dob &&
    errors.dob.message &&
    errors.dob.message === "You must be 13 or older"
  ) {
    SallieMsg = UNDER_13[language];
  }

  const raceOptions = [
    { value: "", label: "" },
    { value: "American Indian or Alaskan Native", label: RACE[language].americanIndianOrAlaskanNative },
    { value: "Asian Indian", label: RACE[language].asianIndian },
    { value: "Black or African American", label: RACE[language].blackOrAfricanAmerican },
    { value: "Chamorro or Guamanian", label: RACE[language].chamorroOrGuamanian },
    { value: "Chinese", label: RACE[language].chinese },
    { value: "Filipino", label: RACE[language].filipino },
    { value: "Japanese", label: RACE[language].japanese },
    { value: "Korean", label: RACE[language].korean },
    { value: "White", label: RACE[language].white },
    { value: "otherRace", label: RACE[language].otherRace },
    { value: "Prefer not to answer", label: RACE[language].preferNotToAnswer }
  ];

  const ethnicityOptions = [
    { value: "", label: "" },
    { value: "Yes", label: ETHNICITY[language].yes },
    { value: "No", label: ETHNICITY[language].no },
    { value: "otherEthnicities", label: ETHNICITY[language].other },
    { value: "notToAnswer", label: ETHNICITY[language].preferNotToAnswer }
  ];

  const veteranOptions = [
    { value: "", label: "" },
    { value: "yes", label: VETERAN[language].yes },
    { value: "no", label: VETERAN[language].no },
    { value: "notToAnswer", label: VETERAN[language].preferNotToAnswer },
  ];

  const genderOptions = [
    { value: "", label: "" },
    { value: "male", label: GENDER[language].male },
    { value: "female", label: GENDER[language].female },
    { value: "other", label: GENDER[language].other },
  ];

  return (
    <>
      <div style={{ display: viewPolicy ? "block" : "none" }}>
        <PrivacyPage
          firstName={firstName}
          lastName={lastName}
          goback={() => {
            setViewPolicy(false);
          }}
          onClick={() => {
            setAgreed(true);
            setViewPolicy(false);
          }}
        />
      </div>
      <div style={{ display: viewPolicyPatient ? "block" : "none" }}>
        <PrivacyPagePatient
          firstName={firstName}
          lastName={lastName}
          goback={() => {
            setViewPolicyPatient(false);
          }}
          onClick={() => {
            setAgreed(true);
            setViewPolicyPatient(false);
          }}
        />
      </div>
      <div
        style={{ display: viewPolicy || viewPolicyPatient ? "none" : "block" }}
      >
        <OnboardingStepHeader text={STEP_1A[language]} />
        <Sallie text={SallieMsg} />
        <Form
          autoComplete="on"
          action="/post"
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Row>
            <Col md={12}>
              {!isProviderForm ? (
                <Form.Group controlId="userType">
                  <Form.Group as={Row} className="registration-type">
                    <Form.Label column className="registration-type-label">
                      {REGISTRATION_TYPE[language].label}<span className="required">*</span>
                    </Form.Label>
                    <Col sm={12}>
                      <Row className="registration-type-radio-row">
                        <Col sm={2}>
                          <Form.Check
                            type="radio"
                            label={REGISTRATION_TYPE[language].patient}
                            name="userType"
                            value="patient"
                            checked={registrationType === "patient"}
                            onChange={handleRegistrationTypeChange}
                            id="registrationTypePatient"
                            className="registration-type-radio"
                            ref={register}
                          />
                        </Col>
                        <Col sm={2}>
                          <Form.Check
                            type="radio"
                            label={REGISTRATION_TYPE[language].caregiver}
                            name="userType"
                            value="caregiver"
                            checked={registrationType === "caregiver"}
                            onChange={handleRegistrationTypeChange}
                            id="registrationTypeCaregiver"
                            className="registration-type-radio"
                            ref={register}
                          />
                        </Col>
                        <Col sm={4}>
                          <Form.Check
                            type="radio"
                            label={REGISTRATION_TYPE[language].provider}
                            name="userType"
                            value="provider"
                            checked={registrationType === "provider"}
                            onChange={handleRegistrationTypeChange}
                            id="registrationTypeProvider"
                            className="registration-type-radio"
                            ref={register}
                          />
                        </Col>
                        <Col sm={4}>
                          <Form.Check
                            type="radio"
                            label={REGISTRATION_TYPE[language].therapist}
                            name="userType"
                            value="Therapist"
                            checked={registrationType === "Therapist"}
                            onChange={handleRegistrationTypeChange}
                            id="registrationTypeTherapist"
                            className="registration-type-radio"
                            ref={register}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                  {errors.userType && (
                    <Form.Control.Feedback type="invalid">
                      {errors.userType.message}
                    </Form.Control.Feedback>
                  )}
                  {registrationType === "patient" && (
                    <>
                      <h6>
                        {PRIMARY_DIAGNOSIS[language].label}
                        <span className="required">*</span>
                      </h6>
                      <Col md={12} className="mt-3">
                        <div style={{ display: "flex" }}>
                          {returnedData.map((itemList, i) => {
                            const { category } = itemList;
                            return (
                              <StrokeListSelecter
                                key={category}
                                category={category}
                                message={PRIMARY_DIAGNOSIS[language][category].category}
                                list={itemList.items}
                                index={i}
                                isTherapist={true}
                                selectedList={selectedData[category]}
                                toggleFromSelectedList={toggleFromSelectedList}
                                selectForTherapist={selectForTherapist}
                                addOther={addOther}
                              />
                            );
                          })}
                        </div>
                      </Col>
                    </>
                  )}
                  {registrationType === "provider" && (
                    <>
                      <Form.Group controlId="providerType">
                        <Form.Label>
                          {PROVIDER_TYPE[language].label} <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                          autoFocus={true}
                          name="providerType"
                          as="select"
                          ref={register}
                          isInvalid={errors.providerType}
                        >
                          {providerTypes
                            ? providerTypes.map((providerType) => (
                              <option
                                key={providerType.constantString}
                                value={providerType.constantString}
                              >
                                {PROVIDER_TYPE[language][providerType.constantString]}
                              </option>
                            ))
                            : null}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.providerType && errors.providerType.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}
                  {errorMessage && (
                    <Form.Control.Feedback
                      style={{ display: "block" }}
                      type="invalid"
                    >
                      Please select one of the status.
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <Form.Group controlId="providerType">
                  <Form.Label>
                    {PROVIDER_TYPE[language].label} <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    autoFocus={true}
                    name="providerType"
                    as="select"
                    ref={register}
                    isInvalid={errors.providerType}
                  >
                    {providerTypes
                      ? providerTypes.map((providerType) => (
                        <option
                          key={providerType.constantString}
                          value={providerType.constantString}
                        >
                          {PROVIDER_TYPE[language][providerType.constantString]}
                        </option>
                      ))
                      : null}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.providerType && errors.providerType.message}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Col>

            {/* {otherType && (
              <Col md={8}>
                <LabelledInput
                  name="otherType"
                  required
                  label="Other Registration Type"
                  inputRef={register}
                  errors={errors}
                />
              </Col>
            )} */}

            <Col md={3}>
              <LabelledInput
                name="firstName"
                required
                label={NAME[language].first}
                autoComplete="given-name"
                onChange={handleFirstNameChange}
                inputRef={register}
                errors={errors}
              />
            </Col>

            <Col md={3}>
              <LabelledInput
                name="lastName"
                autoComplete="family-name"
                label={NAME[language].last}
                onChange={handleLastNameChange}
                required
                inputRef={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <SelectField
                controlId="race"
                label={RACE[language].label}
                name="race"
                options={raceOptions}
                register={register}
                errors={errors}
              />
            </Col>

            <Col md={6}>
              <SelectField
                controlId="ethnicity"
                label={ETHNICITY[language].label}
                name="ethnicity"
                options={ethnicityOptions}
                register={register}
                errors={errors}
              />
            </Col>

            <Col md={3}>
              <SelectField
                controlId="veteran"
                label={VETERAN[language].label}
                name="veteran"
                options={veteranOptions}
                register={register}
                errors={errors}
              />
            </Col>

            <Col md={3}>
              <Form.Group controlId="gender">
                <Form.Label>
                  {GENDER[language].label}<span className="required">*</span>
                </Form.Label>
                <Form.Control
                  name="gender"
                  as="select"
                  ref={register}
                  isInvalid={errors.gender}
                >
                  <option value=""></option>
                  <option value="male">{GENDER[language].male}</option>
                  <option value="female">{GENDER[language].female}</option>
                  <option value="other">{GENDER[language].other}</option>
                </Form.Control>
                {language !== 'spanish' &&
                  <Form.Control.Feedback type="invalid">
                    {errors.gender && errors.gender.message}
                  </Form.Control.Feedback>
                }
              </Form.Group>
            </Col>

            <Col md={6}>
              <LabelledInput
                name="phoneNumber"
                type="tel"
                label={PHONE[language]}
                autoComplete="tel"
                inputRef={register}
                errors={errors}
              />
            </Col>
            {!isProviderForm && (
              <Col md={6}>
                <LabelledInput
                  name="email"
                  type="email"
                  label={EMAIL[language]}
                  autoComplete="username"
                  required
                  inputRef={register}
                  errors={errors}
                />
              </Col>
            )}

            <Col md={6}>
              <LabelledInput
                name="dob"
                type="DOB"
                label={DOB[language]}
                required
                inputRef={register}
                errors={errors}
              />
            </Col>

            {!isProviderForm && (
              <Col md={6}>
                <Row className="d-flex" style={{ marginTop: "1.8rem" }}>
                  <Dropdown
                    position="right"
                    title={referralList.name}
                    menuClassName="text-14 py-8 px-0 my-0 mx-16 border-b-1 border-solid border-blue hover:border-black"
                    style={{
                      marginLeft: "1.3rem",
                      fontWeight: "normal",
                      fontSize: "15px",
                      border: "1px solid #d4d4d4",
                    }}
                  >
                    {referralList.children &&
                      referralList.children.map((item) => (
                        <Dropdown.Item
                          onClick={() => setSelectReferral(item)}
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                  </Dropdown>
                  <span className="required">*</span>
                  <div
                    style={{
                      margin: "0 1rem 1rem 0.5rem",
                      minHeight: "2.3rem",
                      minWidth: "11rem",
                      lineHeight: "2.3rem",
                      fontWeight: "normal",
                      fontSize: "15px",
                      border: "1px solid #d4d4d4",
                      borderRadius: "3px",
                      backgroundColor: "white",
                      alignItems: "center",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    {selectReferral}
                  </div>
                </Row>
              </Col>
            )}
            <Col md={6}>
              <Form.Group controlId="country">
                <Form.Label>{COUNTRY[language]}</Form.Label>
                <Form.Control
                  name="country"
                  as="select"
                  ref={register}
                  defaultValue="US"
                >
                  {Object.keys(CountryList)
                    .sort()
                    .map((countryName) => (
                      <option
                        key={CountryList[countryName]}
                        value={CountryList[countryName]}
                      >
                        {countryName}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <LabelledInput
                name="postalCode"
                autoComplete="postal-code"
                label={ZIPCODE[language]}
                required
                inputRef={register}
                type="text"
                errors={errors}
              />
            </Col>

            <Col md={6}>
              <LabelledInput
                name="password"
                type="password"
                label={PASSWORD[language].password}
                required
                autoComplete="new-password"
                inputRef={register}
                errors={errors}
              />
            </Col>
            <Col md={6}>
              <LabelledInput
                type="password"
                name="passwordConfirmation"
                label={PASSWORD[language].confirm_password}
                autoComplete="new-password"
                required
                inputRef={register}
                errors={errors}
              />
            </Col>
            {(registrationType == "Therapist" ||
              registrationType == "provider" ||
              isProviderForm) && (
                <PolicyComponent
                  label={MESSAGES_AND_ALERTS[language].messages}
                  radioName="radio-type-1"
                  idYes="field-yes-1"
                  idNo="field-no-1"
                  policyValue={messagePolicy}
                  setPolicyValue={setMessagePolicy}
                />
              )}
            {(registrationType == "Therapist" ||
              registrationType == "provider" ||
              isProviderForm) && (
                <PolicyComponent
                  label={MESSAGES_AND_ALERTS[language].alerts}
                  radioName="radio-type-2"
                  idYes="field-yes-2"
                  idNo="field-no-2"
                  policyValue={alertPolicy}
                  setPolicyValue={setAlertPolicy}
                />
              )}

            {(registrationType == "Therapist" ||
              registrationType == "provider" ||
              isProviderForm) && (
                <Col md={12} className="terms-and-conditions">
                  <div>
                    <span>{TERMS_AND_CONDITIONS[language].accept} </span>
                    <LinkButton type="button" onClick={() => setViewPolicy(true)}>
                      <h5>{TERMS_AND_CONDITIONS[language].terms_and_conditions}</h5>
                    </LinkButton>
                  </div>
                  {readAlert && !agreed && (
                    <Form.Control.Feedback
                      style={{ display: "block" }}
                      type="invalid"
                    >
                      Please click and read the green words.
                    </Form.Control.Feedback>
                  )}
                </Col>
              )}

            {(registrationType == "patient" ||
              registrationType == "caregiver") && (
                <Col md={12} className="terms-and-conditions">
                  <div>
                    <span>{TERMS_AND_CONDITIONS[language].accept} </span>
                    <LinkButton type="button" onClick={() => setViewPolicyPatient(true)}>
                      <h5>{TERMS_AND_CONDITIONS[language].terms_and_conditions}</h5>
                    </LinkButton>
                  </div>
                  {readAlert && !agreed && (
                    <Form.Control.Feedback
                      style={{ display: "block" }}
                      type="invalid"
                    >
                      Please click and read the green words.
                    </Form.Control.Feedback>
                  )}
                </Col>
              )}

            <Col md={12}>
              <SubmitButton disabled={isLoading} type="submit">
                {SAVE_AND_CONTINUE[language]} &gt;
              </SubmitButton>
            </Col>
          </Form.Row>
        </Form>
      </div>
    </>
  );
};

export default BasicInfo;
