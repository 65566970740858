import * as yup from "yup"

const DescribeCondition = yup.object().shape({
    dateOfSymptoms: yup
        .date()
        .required("Date of TBI is required")
        .typeError("Enter valid date")
        .max(new Date(), "Enter valid date"),
    describeSymptoms: yup.string().required("Description required"),
    hasTbiBefore: yup
        .string().required("Please check at least one radio").nullable()
})

export default DescribeCondition
