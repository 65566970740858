import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  matchPath,
} from "react-router-dom";
import {
  ThemeContext,
  PatientContext,
  SelectedPatientContext,
  PreferredLanguageProvider
} from "./lib/main-context";
// bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

// components
import format from "date-fns/format";
import Dashboard from "./Components/Dashboard";
import HeaderWithBrand from "./Components/Header/HeaderWithBrand";
import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import PageNotFound from "./Components/PageNotFound";
import HelpPage from "./Components/HelpPage";
import MediaPage from "./Components/MediaPage";
import PatientControl from "./Components/DashboardComponents/PatientControl";
import PatientReferralRecord from "./Components/DashboardComponents/PatientReferralRecord";
import ReferPatientSelection from "./Components/DashboardComponents/ReferPatientSelection";
import { getReferralRecord } from "./api/referralRequest";
import CaseReport from "./Components/DashboardComponents/CaseReport";
// Auth routes
import SignOut from "./Components/SignOut";
import SignIn from "./Components/SignIn/SignIn";
import ResetPassword1 from "./Components/SignIn/ResetPassword1";
import ResetPassword2 from "./Components/SignIn/ResetPassword2";
import AccountSetting from "./Components/AccountSetting/AccountSetting";

// Onboarding routes
import BasicInfo from "./Components/Onboarding/BasicInfo";
import PatientInfo from "./Components/Onboarding/PatientInfo";
import PatientInfoTherapist from "./Components/Onboarding/PatientInfoTherapist";
import RecentTBI1 from "./Components/Onboarding/RecentTBI1";
import RecentTBI2 from "./Components/Onboarding/RecentTBI2";
import RecentCondition1 from "./Components/Onboarding/RecentCondition1";
import RecentCondition2 from "./Components/Onboarding/RecentCondition2";
import RecentStroke1 from "./Components/Onboarding/RecentStroke1";
import RecentStroke2 from "./Components/Onboarding/RecentStroke2";
import SelectLaterSymptoms from "./Components/Onboarding/SelectLaterSymptoms";
import SelectTopSymptoms from "./Components/Onboarding/SelectTopSymptoms";
import SelectTherapies from "./Components/Onboarding/SelectTherapies";
import Register from "./Components/Onboarding/Register";
import SelectSocialDeterminants from "./Components/Onboarding/SelectSocialDeterminants";
import SelectTopTriggers from "./Components/Onboarding/SelectTopTriggers";
import NotificationPrefs from "./Components/Onboarding/NotificationPrefs";
import StartTracking from "./Components/Onboarding/StartTracking";
import EmailSent from "./Components/Onboarding/EmailSent";
import EmailConfirm from "./Components/Onboarding/EmailConfirm";
import EmailConfirmForExistingProvider from "./Components/Onboarding/EmailConfirmForExistingProvider";
import EmailConfirmForExistingTP from "./Components/Onboarding/EmailConfirmForExistingTP";
import PaymentPage from "./Components/Onboarding/PaymentPage";
// auth
import {
  getMe,
  getPatient,
  getPatientList,
  getPatientList2,
  getPatientList3,
  getPatientList4,
  getPatientList5,
} from "./api/auth";
import Warning from "./Components/DashboardComponents/Warning";
import Oops from "./Components/Oops";
import ExpandedChart from "./Components/ExpandedChart";
import MedicalDetailsChart from "./Components/MedicalDetailsChart";
import TriggerDetailsChart from "./Components/TriggerDetailsChart";
import {
  getTrackedData,
  getTopFactors,
  getLastTrackedFeelingToday,
  getTBISymptoms,
  updateDarkModePref,
  getDarkModePref,
} from "./api/TBIRequests";
import Messages from "./Components/Messages";
import { getMessages } from "./api/messageRequests";

const App = () => {
  const history = useHistory();

  const [user, setUser] = useState(null);
  // When setPatient sets the first patient for a new user, use setPatient(<user data>[0]).
  // TODO otherwise we need a way to select which patient will have data displayed and thus is in patient state
  const [patient, setPatient] = useState(null);
  const [patientList, setPatientList] = useState(null);
  const [caregiverList, setCaregiverList] = useState(null);
  const [therapistList, setTherapistList] = useState(null);
  const [routeGroup, setRouteGroup] = useState(null);
  const [onboardingPercent, setOnboardingPercent] = useState(null);

  // used to allow the APIs to respond before loading the routes
  // on app load we get the user using a cookie
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  // Patients are gotten after the user is loaded or changes
  const [isLoadingPatient, setIsLoadingPatient] = useState(true);

  // return data for data tracked by user
  const [trackedData, setTrackedData] = useState(null);
  const [userIdForTrackedData, setUserIdForTrackedData] = useState(null);
  const [loadingTrackedData, setLoadingTrackedData] = useState(true);
  const [errorLoadingTrackedData, setErrorLoadingTrackedData] = useState(false);

  // symptoms user chose to track
  const [topSymptoms, setTopSymptoms] = useState(null);
  const [loadingTopSymptoms, setLoadingTopSymptoms] = useState(true);

  // 'how are you feeling today?' states
  const [doneSimpleTracking, setDoneSimpleTracking] = useState(false);
  const [loadingFeelingToday, setLoadingFeelingToday] = useState(true);

  const [topTrigger, setTopTrigger] = useState(null);
  const [loadingTopTrigger, setLoadingTopTrigger] = useState(true);
  const [errorLoadingTopTrigger, setErrorLoadingTopTrigger] = useState(false);
  const [onOffButtonMessagesStatus, setOnOffButtonMessagesStatus] =
    useState(null);
  const [messages, setMessages] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(false);

  const [referralInfo, setReferralInfo] = useState(null);

  //dark theme controller
  const [dark, setDark] = useState(false);

  const location = useLocation();

  const [inputVisible, setInputVisible] = useState(false);

  useLayoutEffect(() => {
    const lastTheme = window.localStorage.getItem("POP-darkTheme");
    const getDarkMode = async () => {
      try {
        const result = await getDarkModePref();
        if (result.data.dark_mode === true) {
          setDark(true);
          document.body.classList.add("dark-mode");
        } else {
          setDark(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (!lastTheme) {
      getDarkMode();
    } else {
      if (lastTheme === "true") {
        setDark(true);
        document.body.classList.add("dark-mode");
      } else {
        setDark(false);
      }
    }
  }, [dark]);

  const toggleTheme = () => {
    if (dark) {
      document.body.classList.remove("dark-mode");
      updateDarkModePref({ darkMode: false }).catch((err) => {
        console.log("darkMode: false err");
      });
    } else {
      document.body.classList.add("dark-mode");
      updateDarkModePref({ darkMode: true }).catch((err) => {
        console.log("darkMode: true err");
      });
    }
    setDark(!dark);

    localStorage.setItem("POP-darkTheme", !dark);
  };

  // What we want is for user data to reloaded whenever you switch to an onboarding page
  // from a non-onboarding page or a dashboard page from a non-dashboard page
  // so you have to group strings for these routes in a JS object, with an "other" key
  // for pages like media and help where you don't need to reload user data

  // But this is much better done with pathname routes. Leave that as a TODO

  const routeGroups = {
    onboarding: [
      "/register",
      "/BasicInfo",
      "/RegisterPatient",
      "/Verification/EmailSent",
      "/Verification/EmailConfirm",
      "/RecentTBI1",
      "/RecentTBI2",
      "/RecentCondition1",
      "/RecentCondition2",
      "/RecentStroke1",
      "/RecentStroke2",
      "/selectTopSymptoms",
      "/selectTrigger",
      "/selectTopTriggers",
      "/selectTherapies",
      "/notificationPrefs",
      "/startTracking",
      "/PaymentPage",
    ],
    laterSymptoms: ["/laterSymptoms"], //unique layout
    dashboard: ["/", "/charts/:symptom", "/patientControl", "/caseReport"],
    other: [
      // "/laterSymptoms",
      "/warning",
      "/help",
      "/media",
      "/oops",
      "/signOut",
      "/signIn",
      "/resetPassword1",
      "/resetPassword2",
      "*",
    ],
  };

  useEffect(() => {
    const path = location.pathname;
    const newRoutegroup = Object.keys(routeGroups).find((key) => {
      // matchPath returns object, coerced to boolean for find method
      return matchPath(path, {
        path: routeGroups[key],
        exact: true,
      });
    });
    if (routeGroup !== newRoutegroup) setRouteGroup(newRoutegroup);
  }, [location]);

  // auto sign in user
  useEffect(() => {
    if (routeGroup === "other") {
      return;
    }
    setIsLoadingUser(true);
    getMe()
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        setIsLoadingPatient(false);
        if (err.response && err.response.status === 401) {
          //setting darkmode to false when user is not logged in
          document.body.classList.remove("dark-mode");
          setDark(false);
        } else {
          history.push("/oops");
        }
      })
      .finally(() => setIsLoadingUser(false));
  }, [routeGroup]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoadingPatient(true);
  //     try {
  //       if (user) {
  //         if (user.userType === "caregiver" || user.userType === "Therapist") {
  //           const res = await getPatientList3();
  //           setPatientList(res.data);
  //         }
  //         if (user.userType === "Therapist") {
  //           const resCaregiver = await getPatientList5();
  //           if (resCaregiver.data.length !== 0) {
  //             setCaregiverList(resCaregiver.data);
  //           }
  //         } else if (user.userType === "provider") {
  //           const [res4, resCaregiver, resPatient] = await Promise.all([
  //             getPatientList4(),
  //             getPatientList(),
  //             getPatientList2(),
  //           ]);

  //           if (res4.data.length !== 0) {
  //             setTherapistList(res4.data);
  //           }

  //           if (resCaregiver.data.length !== 0) {
  //             setCaregiverList(resCaregiver.data);
  //           }

  //           if (resPatient.data.length !== 0) {
  //             setPatientList(resPatient.data);
  //           }
  //         } else if (user.userType === "patient") {
  //           const res = await getPatient();
  //           setPatient(res.data);
  //         }
  //       }
  //     } catch (error) {
  //       history.push("/oops");
  //     }
  //     setIsLoadingPatient(false);
  //   };
  //   if (user) {
  //     fetchData();
  //   }

  //   return () => {
  //     // clean for patient control
  //     setTherapistList(null);
  //     setCaregiverList(null);
  //     setPatientList(null);
  //     // setPatient(null)
  //   };
  // }, [user]);

  useEffect(() => {
    let isMounted = true; // Flag to track mounted status

    const fetchData = async () => {
      setIsLoadingPatient(true);
      try {
        if (user) {
          if (user.userType === "caregiver" || user.userType === "Therapist") {
            const res = await getPatientList3();
            if (isMounted) setPatientList(res.data);
          }
          if (user.userType === "Therapist") {
            const resCaregiver = await getPatientList5();
            if (isMounted && resCaregiver.data.length !== 0) {
              setCaregiverList(resCaregiver.data);
            }
          } else if (user.userType === "provider") {
            const [res4, resCaregiver, resPatient] = await Promise.all([
              getPatientList4(),
              getPatientList(),
              getPatientList2(),
            ]);

            if (isMounted && res4.data.length !== 0) {
              setTherapistList(res4.data);
            }

            if (isMounted && resCaregiver.data.length !== 0) {
              setCaregiverList(resCaregiver.data);
            }

            if (isMounted && resPatient.data.length !== 0) {
              setPatientList(resPatient.data);
            }
          } else if (user.userType === "patient") {
            const res = await getPatient();
            if (isMounted) setPatient(res.data);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        history.push("/oops");
      } finally {
        if (isMounted) setIsLoadingPatient(false);
      }
    };

    if (user) {
      fetchData();
    }

    return () => {
      // Cleanup function: Update only if component is still mounted
      isMounted = false;
    };
  }, [user]); // Run effect whenever 'user' changes

  // used to 'retry' within dashboard buttons if data fails to load
  const loadTrackedData = useCallback(() => {
    setLoadingTrackedData(true);
    if (user.userType == "provider" && userIdForTrackedData && patient) {
      //userIdForTrackedData means CG's or TP's patient is clicked
      getTrackedData({
        patientId: patient.patientId,
        userId: userIdForTrackedData,
      })
        .then(({ data }) => {
          setTrackedData(data);
          setLoadingTrackedData(false);
          setErrorLoadingTrackedData(false);
        })
        .catch(() => {
          setErrorLoadingTrackedData(true);
          setLoadingTrackedData(false);
        });
    } else if (
      user.userType == "provider" &&
      !userIdForTrackedData &&
      patient
    ) {
      // this is a provider and it's a patient user being clicked on patient control page
      getTrackedData({ patientId: patient.patientId })
        .then(({ data }) => {
          setTrackedData(data);
          setLoadingTrackedData(false);
          setErrorLoadingTrackedData(false);
        })
        .catch(() => {
          setErrorLoadingTrackedData(true);
          setLoadingTrackedData(false);
        });
    } else if (user.userType == "Therapist" || user.userType == "caregiver") {
      getTrackedData({ patientId: patient.patientId, userId: user.userId })
        .then(({ data }) => {
          setTrackedData(data);
          setLoadingTrackedData(false);
          setErrorLoadingTrackedData(false);
        })
        .catch(() => {
          setErrorLoadingTrackedData(true);
          setLoadingTrackedData(false);
        });
    } else {
      getTrackedData({ patientId: patient.patientId })
        .then(({ data }) => {
          setTrackedData(data);
          setLoadingTrackedData(false);
          setErrorLoadingTrackedData(false);
        })
        .catch(() => {
          setErrorLoadingTrackedData(true);
          setLoadingTrackedData(false);
        });
    }
  }, [patient]);
  // each time there is a new patient, we need to get new data
  useEffect(() => {
    if (patient) {
      loadTrackedData();
    }
  }, [patient, loadTrackedData]);

  const loadTopSymptoms = useCallback(() => {
    setLoadingTopSymptoms(true);
    if (patient) {
      getTopFactors(patient.patientId)
        .then(({ data }) => {
          setTopSymptoms(data);
          setLoadingTopSymptoms(false);
        })
        .catch(() => {
          history.push("/oops");
          setLoadingTopSymptoms(false);
        });
    }
  }, [history, location.pathname, patient]);

  // run once per patient change by default
  useEffect(() => {
    loadTopSymptoms();
  }, [patient, location.pathname, loadTopSymptoms]); //location.pathname is important, when users finished register and click login, the dashboard need to load tracking symptoms

  useEffect(() => {
    if (patient) {
      // no catch because it's ok if we don't get this data
      // it'll just show the 'feeling today' again, which is fine.
      getLastTrackedFeelingToday(patient.patientId).then(({ data }) => {
        if (data.length === 0) {
          setDoneSimpleTracking(false);
          setLoadingFeelingToday(false);
          return;
        } else {
          const lastEntry = format(new Date(data.symptom_date), "MM-dd-yyyy");
          const today = format(new Date(), "MM-dd-yyyy");
          if (lastEntry === today) setDoneSimpleTracking(true);
          // else setDoneSimpleTracking(false);
        }
        setLoadingFeelingToday(false);
      });
    }
  }, [patient]);

  const loadTrigger = useCallback(() => {
    setErrorLoadingTopTrigger(false);
    setLoadingTopTrigger(true);
    if (patient) {
      getTBISymptoms()
        .then(({ data }) => {
          setTopTrigger(data);
        })
        .catch(() => setErrorLoadingTopTrigger(true))
        .finally(() => setLoadingTopTrigger(false));
    }
  }, [patient]);

  useEffect(() => {
    loadTrigger();
  }, [patient, loadTrigger]);

  const loadMessages = useCallback(() => {
    setLoadingMessages(true);
    if (user && patient) {
      getMessages()
        .then((res) => {
          setMessages(res.data);
        })
        .catch((err) => {
          console.error(err);
          history.push("/oops");
        })
        .finally(() => {
          setLoadingMessages(false);
        });
    }
  }, [user, patient]);

  useEffect(() => {
    loadMessages();
  }, [user, patient]);

  useEffect(() => {
    // setLoading(true)
    if (user) {
      getReferralRecord()
        .then((response) => {
          setReferralInfo(response.data);
        })
        .catch((err) => {
          console.log("getReferralRecord err");
        });
    }
  }, []);

  const resetApp = () => {
    setUser(null);
    setPatient(null);
    setTrackedData(null);
    setErrorLoadingTrackedData(false);
    setTopSymptoms(null);
    setTopTrigger(null);
  };
  return (
    <Container style={{ marginBottom: "5rem" }}>
      <PreferredLanguageProvider user={user}>
        <ThemeContext.Provider
          value={{
            dark,
            toggleTheme,
          }}
        >
          <PatientContext.Provider value={{ patient }}>
            <HeaderWithBrand
              user={user}
              onboardingPercent={onboardingPercent}
              routeGroup={routeGroup}
            />
            <Row className="justify-content-center">
              <Col
                xs={routeGroup === "onboarding" ? 10 : 12}
                md={routeGroup === "onboarding" ? 8 : 12}
              >
                {isLoadingUser ||
                  (isLoadingPatient && (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ))}
                {!isLoadingUser && !isLoadingPatient && (
                  <Switch>
                    <AuthenticatedRoute user={user} exact path="/">
                      <Dashboard
                        therapistList={therapistList}
                        patientList={patientList}
                        caregiverList={caregiverList}
                        setTherapistList={setTherapistList}
                        setCaregiverList={setCaregiverList}
                        setPatientList={setPatientList}
                        loadingTopSymptoms={loadingTopSymptoms}
                        topSymptoms={topSymptoms}
                        setTopSymptoms={setTopSymptoms}
                        setLoadingTopSymptoms={setLoadingTopSymptoms}
                        trackedData={trackedData}
                        loadingTrackedData={loadingTrackedData}
                        errorLoadingTrackedData={errorLoadingTrackedData}
                        loadTrackedData={loadTrackedData}
                        user={user}
                        loadingFeelingToday={loadingFeelingToday}
                        doneSimpleTracking={doneSimpleTracking}
                        setDoneSimpleTracking={setDoneSimpleTracking}
                        loadingTopTrigger={loadingTopTrigger}
                        topTrigger={topTrigger}
                        errorLoadingTopTrigger={errorLoadingTopTrigger}
                        loadTrigger={loadTrigger}
                        messages={messages}
                        loadMessages={loadMessages}
                        onOffButtonMessagesStatus={onOffButtonMessagesStatus}
                        setOnOffButtonMessagesStatus={
                          setOnOffButtonMessagesStatus
                        }
                        inputVisible={inputVisible}
                        setInputVisible={setInputVisible}
                      />
                    </AuthenticatedRoute>

                    <AuthenticatedRoute exact path="/warning" user={user}>
                      <Warning user={user} />
                    </AuthenticatedRoute>

                    <Route exact path="/help">
                      <HelpPage />
                    </Route>

                    <Route exact path="/media">
                      <MediaPage />
                    </Route>

                    <Route exact path="/oops">
                      <Oops />
                    </Route>

                    <AuthenticatedRoute user={user} path="/charts/:symptom?">
                      {loadingTopSymptoms || loadingTrackedData ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <ExpandedChart
                          user={user}
                          userIdForTrackedData={userIdForTrackedData}
                          topSymptoms={topSymptoms}
                          error={errorLoadingTrackedData}
                          retry={loadTrackedData}
                          data={trackedData}
                        />
                      )}
                    </AuthenticatedRoute>

                    <AuthenticatedRoute user={user} path="/caseReport">
                      <CaseReport
                        patient={patient}
                        topSymptoms={topSymptoms}
                        user={patient}
                        userIdForTrackedData={userIdForTrackedData}
                        error={errorLoadingTrackedData}
                        retry={loadTrackedData}
                        data={trackedData}
                      />
                      {/* </SelectedPatientContext.Provider> */}
                    </AuthenticatedRoute>
                    <AuthenticatedRoute user={user} path="/medicalDetailsChart">
                      {loadingTopSymptoms || loadingTrackedData ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <MedicalDetailsChart
                          topSymptoms={topSymptoms}
                          error={errorLoadingTrackedData}
                          retry={loadTrackedData}
                          data={trackedData}
                        />
                      )}
                    </AuthenticatedRoute>

                    <AuthenticatedRoute user={user} path="/triggerDetailsChart">
                      {loadingTopSymptoms || loadingTrackedData ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        <TriggerDetailsChart
                          topSymptoms={topSymptoms}
                          error={errorLoadingTrackedData}
                          retry={loadTrackedData}
                          data={trackedData}
                        />
                      )}
                    </AuthenticatedRoute>

                    {/* May have to change this to plain Route if bugs arise */}
                    <AuthenticatedRoute user={user} path="/messages">
                      <Messages
                        user={user}
                        messages={messages}
                        setMessages={setMessages}
                        loadingMessages={loadingMessages}
                      />
                    </AuthenticatedRoute>

                    {/* Auth Routes--------- */}
                    <AuthenticatedRoute user={user} exact path="/signOut">
                      <SignOut resetApp={resetApp} />
                    </AuthenticatedRoute>

                    <AuthenticatedRoute user={user} path="/accountSetting">
                      <AccountSetting />
                    </AuthenticatedRoute>

                    <Route exact path="/signIn">
                      <SignIn setUser={setUser} user={user} />
                    </Route>

                    <AuthenticatedRoute
                      user={user}
                      patientList={patientList}
                      caregiverList={caregiverList}
                      therapistList={therapistList}
                      exact
                      path="/patientControl"
                    >
                      <PatientControl
                        user={user}
                        patientList={patientList}
                        caregiverList={caregiverList}
                        therapistList={therapistList}
                        setPatient={setPatient}
                        setUserIdForTrackedData={setUserIdForTrackedData}
                      />
                    </AuthenticatedRoute>

                    <AuthenticatedRoute
                      user={user}
                      exact
                      path="/ReferPatientSelection"
                    >
                      <ReferPatientSelection
                        user={user}
                        patientList={patientList}
                        caregiverList={caregiverList}
                        therapistList={therapistList}
                        inputVisible={inputVisible}
                        setInputVisible={setInputVisible}
                      />
                    </AuthenticatedRoute>

                    <AuthenticatedRoute
                      user={user}
                      exact
                      path="/PatientReferralRecord"
                    >
                      <PatientReferralRecord
                        user={user}
                        referralInfo={referralInfo}
                        setReferralInfo={setReferralInfo}
                      />
                    </AuthenticatedRoute>

                    <Route exact path="/resetPassword1">
                      <ResetPassword1 />
                    </Route>

                    <Route path="/resetPassword2">
                      <ResetPassword2 />
                    </Route>
                    {/* END AUTH */}

                    {/* ONBOARDING START */}
                    <Route exact path="/register">
                      <Register setOnboardingPercent={setOnboardingPercent} />
                    </Route>

                    <Route user={user} exact path="/BasicInfo">
                      <BasicInfo
                        user={user}
                        setPatient={setPatient}
                        setPatientList={setPatientList}
                        setUser={setUser}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    {/* Patient info now checks for user like AuthenticatedRoute but not for patient */}
                    <Route exact path="/RegisterPatient">
                      <PatientInfo
                        setPatientList={setPatientList}
                        setOnboardingPercent={setOnboardingPercent}
                        user={user}
                        setPatient={setPatient}
                      />
                    </Route>

                    <Route exact path="/RegisterPatientTherapist">
                      <PatientInfoTherapist
                        setPatientList={setPatientList}
                        setOnboardingPercent={setOnboardingPercent}
                        user={user}
                        setPatient={setPatient}
                      />
                    </Route>

                    <Route user={user} exact path="/Verification/EmailSent">
                      <EmailSent
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route
                      user={user}
                      path="/Verification/EmailConfirmForExistingProvider"
                    >
                      <EmailConfirmForExistingProvider
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route
                      user={user}
                      path="/Verification/EmailConfirmForExistingTP"
                    >
                      <EmailConfirmForExistingTP
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <AuthenticatedRoute
                      user={user}
                      path="/Verification/EmailConfirm"
                    >
                      <EmailConfirm
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </AuthenticatedRoute>

                    <Route user={user} exact path="/PaymentPage">
                      <PaymentPage
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route user={user} exact path="/RecentStroke1">
                      <RecentStroke1
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route user={user} exact path="/RecentStroke2">
                      <RecentStroke2
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route user={user} exact path="/RecentTBI1">
                      <RecentTBI1
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route user={user} exact path="/RecentTBI2">
                      <RecentTBI2
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route user={user} exact path="/RecentCondition1">
                      <RecentCondition1
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route user={user} exact path="/RecentCondition2">
                      <RecentCondition2
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route user={user} exact path="/laterSymptoms">
                      <SelectLaterSymptoms
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>
                    <Route user={user} exact path="/selectTopSymptoms">
                      <SelectTopSymptoms
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>
                    <Route user={user} exact path="/selectTrigger">
                      <SelectSocialDeterminants
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route user={user} exact path="/selectTopTriggers">
                      <SelectTopTriggers
                        patient={patient}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route user={user} exact path="/selectTherapies">
                      <SelectTherapies
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>

                    <Route user={user} exact path="/notificationPrefs">
                      <NotificationPrefs
                        user={user}
                        setOnboardingPercent={setOnboardingPercent}
                      />
                    </Route>
                    <AuthenticatedRoute user={user} exact path="/startTracking">
                      <StartTracking setUser={setUser} user={user} />
                    </AuthenticatedRoute>
                    {/* END ONBOARDING   */}
                    {/* 404 */}
                    <Route path="*">
                      <PageNotFound />
                    </Route>
                  </Switch>
                )}
              </Col>
            </Row>
          </PatientContext.Provider>
        </ThemeContext.Provider>
      </PreferredLanguageProvider>
    </Container>
  );
};

export default App;
