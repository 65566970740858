import * as yup from "yup";

const inviteTPSchema = yup.object().shape({
  therapistEmail: yup
    .string()
    .email("Enter a valid email")
    .required("Email must not be blank"),
});

export default inviteTPSchema;
