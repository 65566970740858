import React, { useState, useEffect, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import {
  PATIENT_INFO,
  NAME,
  GENDER,
  DOB,
  RELATIONSHIP,
  PRIMARY_DIAGNOSIS,
  CHOOSE,
  CONFIRM
} from "../../constants/OnboardingTranslation";

// Hooks
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import useListSelector from "../../lib/useListSelector";
import StrokeListSelecter from "../StrokeListSelecter";
import { yupResolver } from "@hookform/resolvers";

// Bootstrap
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

// Validation Rules
import PatientSchema from "./validations/registerPatientValidation";

// api
import { getPatientList, registerPatient } from "../../api/auth";
import { createGoals, getPatientTypes } from "../../api/TBIRequests";

// Components
import Sallie from "../Sallie";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";

const PatientInfo = ({
  setOnboardingPercent,
  setPatientList,
  user,
  setPatient,
}) => {
  const { language } = useContext(PreferredLanguageContext);

  const types = [
    {
      category: "TBI/Concussion",
      items: PRIMARY_DIAGNOSIS[language]["TBI/Concussion"].items,
    },
    {
      category: "Dementia",
      items: PRIMARY_DIAGNOSIS[language].Dementia.items,
    },
    {
      category: "Stroke",
      items: PRIMARY_DIAGNOSIS[language].Stroke.items,
    },
    {
      category: "Other",
      items: PRIMARY_DIAGNOSIS[language].Other.items,
    },
  ];

  const [returnedData, setReturnedData] = useState([]);
  const {
    toggleFromSelectedList,
    switchSingleSelection,
    selectForTherapist,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    location.state?.fromPatientControl
      ? setOnboardingPercent(20)
      : setOnboardingPercent(15);
    setReturnedData(types);
    initialize(types);
  }, [language]);

  const [isLoading, setIsLoading] = useState(false);
  const [otherRelationshipType, setOtherRelationshipType] = useState(false);
  const [otherStatus, setOtherStatus] = useState(false);
  const [patientTypes, setPatientTypes] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(PatientSchema),
  });

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted
    getPatientTypes()
      .then((res) => {
        if (isMounted) {
          const { data } = res;
          setPatientTypes(data);
        }
      })
      .catch((error) => {
        // Handle any errors
        setError(true);
      });
    // Cleanup function
    return () => {
      isMounted = false; // Set isMounted to false when the component is unmounted
    };
  }, []);

  const onChange = (e) => {
    if (e.target.name === "status") {
      setOtherStatus(e.target.value === "Other");
    } else if (e.target.name === "relationshipType") {
      setOtherRelationshipType(e.target.value === "other");
    }
  };

  const onSubmit = async (data) => {
    if (
      selectedData["TBI/Concussion"] &&
      selectedData["TBI/Concussion"].length === 0 &&
      selectedData.Dementia.length === 0 &&
      selectedData.Stroke.length === 0 &&
      selectedData.Other.length === 0
    ) {
      setErrorMessage(true);
      return;
    }
    for (const key in selectedData) {
      if (selectedData[key].length > 0) {
        data["patientType"] = key;
        data["patientSubType"] = selectedData[key][0];
      }
    }
    try {
      setIsLoading(true);
      const responsePatientData = await registerPatient(data);
      setPatient(responsePatientData.data);
      await createGoals({ patientId: responsePatientData.data.patientId });
      const getPatientListResponse = await getPatientList();
      setPatientList(getPatientListResponse.data);
      if ("Stroke" == data.patientType) {
        history.push("/RecentStroke1", {
          fromPatientControl: false,
        });
      } else if ("TBI/Concussion" == data.patientType) {
        history.push("/RecentTBI1", {
          fromPatientControl: location.state?.fromPatientControl,
        });
      } else {
        history.push("/RecentCondition1", {
          fromPatientControl: location.state?.fromPatientControl,
        });
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      history.push("/oops");
    }
  };

  // Taken from AuthenticatedRoute, patient is still null but user should be set
  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/signIn",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <>
      <OnboardingStepHeader text={PATIENT_INFO[language].header} />
      <Sallie text={PATIENT_INFO[language].sallie} />
      <Form
        autoComplete="on"
        action="/post"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Row>
          <Col md={6}>
            <LabelledInput
              name="firstName"
              required
              label={NAME[language].first}
              autoComplete="given-name"
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <LabelledInput
              name="lastName"
              autoComplete="family-name"
              label={NAME[language].last}
              required
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <Form.Group controlId="gender">
              <Form.Label>
                {GENDER[language].label}<span className="required">*</span>
              </Form.Label>
              <Form.Control
                name="gender"
                as="select"
                ref={register}
                isInvalid={errors.gender}
              >
                <option value=""></option>
                <option value="male">{GENDER[language].male}</option>
                <option value="female">{GENDER[language].female}</option>
                <option value="other">{GENDER[language].other}</option>
              </Form.Control>
              {language !== 'spanish' &&
                <Form.Control.Feedback type="invalid">
                  {errors.gender && errors.gender.message}
                </Form.Control.Feedback>
              }
            </Form.Group>
          </Col>

          <Col md={6}>
            <LabelledInput
              name="dob"
              type="DOB"
              label={DOB[language]}
              required
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <LabelledInput
              name="externalId"
              label={PATIENT_INFO[language].id}
              inputRef={register}
              errors={errors}
            />
          </Col>

          <h6>
            {RELATIONSHIP[language].label}<span className="required">*</span>
          </h6>
          <Col md={12}>
            <Form.Group controlId="relationshipType">
              {[
                { label: RELATIONSHIP[language].parent, value: "parent" },
                { label: RELATIONSHIP[language].spouse, value: "spouse" },
                { label: RELATIONSHIP[language].friend, value: "friend" },
                { label: RELATIONSHIP[language].child, value: "child" },
                { label: RELATIONSHIP[language].other, value: "other" },
              ].map((relationship, index) => (
                <>
                  <Form.Check
                    inline
                    label={relationship.label}
                    value={relationship.value}
                    type="radio"
                    name="relationshipType"
                    id={`inline-radio-relationship-type-${relationship.value}`}
                    onChange={onChange}
                    key={index}
                    ref={register}
                  />
                </>
              ))}
              {errors.relationshipType && (
                <Form.Control.Feedback className="d-block" type="invalid">
                  {RELATIONSHIP[language].error}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {otherRelationshipType && (
              <>
                <Form.Group controlId="otherRelationship">
                  <h6>{RELATIONSHIP[language].relationship}</h6>
                  <Form.Control
                    ref={register}
                    type="text"
                    name="otherRelationship"
                    isInvalid={errors.otherRelationship}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.otherRelationship &&
                      errors.otherRelationship.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )}
          </Col>
        </Form.Row>
        <Form.Group>
          {patientTypes && (
            <>
              <h6>
                {PRIMARY_DIAGNOSIS[language].label}
                <span className="required">*</span>
              </h6>
              <Col md={12} className="mt-3">
                <div style={{ display: "flex" }}>
                  {returnedData.map((itemList, i) => {
                    const { category } = itemList;
                    return (
                      <StrokeListSelecter
                        key={i}
                        category={category}
                        message={PRIMARY_DIAGNOSIS[language][category].category}
                        list={itemList.items}
                        index={i}
                        // maxSelectAmount={1}
                        isTherapist={true}
                        selectedList={selectedData[category]}
                        toggleFromSelectedList={toggleFromSelectedList}
                        // selectOne={switchSingleSelection}
                        selectForTherapist={selectForTherapist}
                        addOther={addOther}
                      />
                    );
                  })}
                </div>
              </Col>
            </>
          )}
          {errorMessage && (
            <Form.Control.Feedback style={{ display: "block" }} type="invalid">
              {CHOOSE[language].error}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Col md={12}>
          <SubmitButton disabled={isLoading} type="submit">
            {CONFIRM[language]}
          </SubmitButton>
        </Col>
        {errors.status && (
          <Form.Control.Feedback className="d-block" type="invalid">
            {errors.status && errors.status.message}
          </Form.Control.Feedback>
        )}
      </Form>
    </>
  );
};

export default PatientInfo;
