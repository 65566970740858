import React, { useState, useEffect, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { PATIENT_STATUS } from "../../constants/DashboardTranslation";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "../../index.scss";
import { updatePatientStatus, getPatientStatus } from "../../api/TBIRequests";
import LoadingSpinner from "../../Components/LoadingSpinner";

const PatientStatus = ({
  status,
  setStatus,
  userType,
  patientId,
  therapistList,
  patientList,
  caregiverList,
  setTherapistList,
  setCaregiverList,
  setPatientList,
}) => {
  const { language } = useContext(PreferredLanguageContext);
  const [loading, setLoading] = useState(false);
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState(null);

  useEffect(() => {
    setLoading(true); // Explicitly set loading to true at the start.

    // Set up a flag to track whether the component is still mounted.
    let isComponentMounted = true;

    getPatientStatus({ userType, patientId })
      .then((response) => {
        if (isComponentMounted) {
          // Only update state if the component is still mounted.
          setStatus(response.data.patient_status);
          setButtonBackgroundColor(
            getStatusColor(response.data.patient_status)
          );
        }
      })
      .catch((err) => {
        if (isComponentMounted) {
          console.error("Error fetching patient status:", err);
        }
      })
      .finally(() => {
        if (isComponentMounted) {
          setLoading(false); // Indicate that loading has finished, whether successful or not.
        }
      });

    // Cleanup function to set isComponentMounted to false when unmounted
    return () => {
      isComponentMounted = false;
    };
  }, []);

  //auto refresh
  const updatePatientStatusOnPatientControlPage = ({
    patientId,
    newPatientStatus,
  }) => {
    const updatedPatientList = patientList?.map((patient) => {
      if (patient.patientId === patientId) {
        return { ...patient, patientStatus: newPatientStatus };
      }
      return patient;
    });

    const updatedTherapistPatientList = therapistList?.map((patient) => {
      if (patient.patientId === patientId) {
        return { ...patient, patientStatus: newPatientStatus };
      }
      return patient;
    });

    const updatedCaregiverPatientList = caregiverList?.map((patient) => {
      if (patient.patientId === patientId) {
        return { ...patient, patientStatus: newPatientStatus };
      }
      return patient;
    });
    // Set the updated patient lists
    setPatientList(updatedPatientList);
    setTherapistList(updatedTherapistPatientList);
    setCaregiverList(updatedCaregiverPatientList);
  };

  const handleStatusChange = async (newStatus) => {
    try {
      const { value } = newStatus;
      setLoading(true);

      if (value !== status) {
        await updatePatientStatus({
          userType,
          patientId,
          newPatientStatus: value,
        });
      }
      await updatePatientStatusOnPatientControlPage({
        patientId,
        newPatientStatus: value,
      });
      setStatus(value);
      setButtonBackgroundColor(getStatusColor(value));
    } catch (error) {
      console.error("Error updating patient status:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (statusValue) => {
    switch (statusValue) {
      case "Active":
        return "green";
      case "Referred":
        return "gray";
      case "Released":
        return "red";
      default:
        return "";
    }
  };

  const options = [
    { value: "Active", label: PATIENT_STATUS[language].options.active },
    { value: "Referred", label: PATIENT_STATUS[language].options.referred, className: "myOptionClassName" },
    { value: "Released", label: PATIENT_STATUS[language].options.released, className: "myOptionClassName" },
  ];

  return (
    <div className="d-flex justify-content-center patient-status pt-2 pb-2 pl-2 pr-2">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Dropdown
          options={options}
          value={status}
          onChange={handleStatusChange}
          placeholder={PATIENT_STATUS[language].placeholder}
          controlClassName={`${buttonBackgroundColor}`}
        />
      )}
    </div>
  );
};

export default PatientStatus;
