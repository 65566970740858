import React, { useState, useEffect, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { PATIENT_CONTROL } from "../../constants/DashboardTranslation";

import { useHistory, Redirect, Link } from "react-router-dom";

import "../../index.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Select from "react-select";

import Sallie from "../Sallie";
import PillButton from "../StyledComponents/PillButton";
import ComponentWrapper from "./ComponentWrapper";
import SmLinkButton from "../StyledComponents/smLinkButton";

import Check from "../../images/checkWithinCircle.svg";

const PatientControl = ({
  user,
  patientList,
  caregiverList,
  therapistList,
  setPatient,
  setUserIdForTrackedData,
}) => {
  const { language } = useContext(PreferredLanguageContext);

  const [selectedPatientTp, setSelectedPatientTp] = useState(null);
  const [selectedPatientCg, setSelectedPatientCg] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const therapists = therapistList?.map((c) => {
    return c.caregiverName;
  });
  let uniqueTherapists = [];
  therapists &&
    therapists.forEach((c) => {
      if (!uniqueTherapists.includes(c)) {
        uniqueTherapists.push(c);
      }
    });

  const caregivers = caregiverList?.map((c) => {
    return c.caregiverName;
  });

  let uniqueCaregivers = [];
  caregivers &&
    caregivers.forEach((c) => {
      if (!uniqueCaregivers.includes(c)) {
        uniqueCaregivers.push(c);
      }
    });

  const createItemList = (res) => {
    return uniqueCaregivers.map((caregiver) => {
      const items = res.filter((item) => item.caregiverName === caregiver);
      return { caregiver, items };
    });
  };

  const createItemList2 = (res) => {
    return uniqueTherapists.map((caregiver) => {
      const items = res.filter((item) => item.caregiverName === caregiver);
      return { caregiver, items };
    });
  };

  const lastList = createItemList(caregiverList);
  const lastList2 = createItemList2(therapistList);

  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [noPatientError, setNoPatientError] = useState(false);

  useEffect(() => {
    if (
      !(
        user.userType == "caregiver" ||
        user.userType == "provider" ||
        user.userType == "Therapist"
      )
    )
      history.push("/");
  }, []);

  const handleSubmit = (patient) => {
    setSubmitting(true);
    if (!patient) {
      setNoPatientError(true);
    } else {
      setPatient(patient);
      setUserIdForTrackedData(patient.caregiverId);
      history.push("/");
    }
    setSubmitting(false);
  };

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/signIn",
        }}
      />
    );
  }
  const patientListOptions = patientList
    ?.map((patient) => ({
      value: patient.patientId,
      label: `${patient.firstName} ${patient.lastName}${patient.externalId ? `, ID: ${patient.externalId}` : ""}${patient.incompletedRegister ? PATIENT_CONTROL[language].patient_list_options.incomplete_registration : ""
        } ${patient.patientStatus === "Released" ? PATIENT_CONTROL[language].patient_list_options.released : ""}`,
    }))
    .sort((a, b) => a?.label.localeCompare(b?.label));

  return (
    <div>
      <Sallie
        text={
          noPatientError
            ? PATIENT_CONTROL[language].sallie.no_patient_error
            : user.userType === "provider"
              ? PATIENT_CONTROL[language].sallie.provider
              : PATIENT_CONTROL[language].sallie.non_provider
        }
      />
      <div className="w-100 p-3 mx-auto">
        <ComponentWrapper>
          <h6 className="d-block mb-4">
            {patientList && patientList.length === 0
              ? PATIENT_CONTROL[language].patient_select.no_patient_error
              : user.userType == "provider"
                ? PATIENT_CONTROL[language].patient_select.provider
                : PATIENT_CONTROL[language].patient_select.non_provider}
          </h6>
          <Row className="d-flex justify-content-center">
            {patientList && (
              <Col md={4}>
                <h5 className="mb-4">{PATIENT_CONTROL[language].patient_list.label}</h5>
                <Select
                  options={patientListOptions}
                  value={patientListOptions.find(
                    (option) => option.value === selectedPatient
                  )}
                  placeholder={PATIENT_CONTROL[language].patient_list_options.select}
                  onChange={(selectedOption) => {
                    setSelectedPatient(selectedOption.value);
                    // handleSubmit(arr.items.find((patient) => patient.patientId === selectedOption.value));
                  }}
                />
                {patientList &&
                  patientList
                    .sort((a, b) => a?.firstName?.localeCompare(b?.firstName))
                    .map((patient, index) => {
                      return (
                        <Col>
                          {" "}
                          {patient.patientId === selectedPatient && (
                            <PillButton
                              autoMargin
                              key={index}
                              onClick={() => {
                                handleSubmit(patient);
                              }}
                              variant={
                                patient.patientStatus === "Released"
                                  ? "gray"
                                  : patient === patient.patientId
                                    ? "info"
                                    : "secondary"
                              }
                            >
                              {patient === patient.patientId ? (
                                <img alt="Checked" src={Check} />
                              ) : (
                                ""
                              )}
                              {`${patient.firstName} ${patient.lastName}`}
                              {patient.externalId && `, ID: ${patient.externalId}`}
                            </PillButton>
                          )}
                        </Col>
                      );
                    })}
              </Col>
            )}

            {user.userType != "caregiver" && caregiverList && (
              <Col md={4}>
                <h5 className="mb-4">{PATIENT_CONTROL[language].patient_list.caregiver}</h5>
                {user.userType != "caregiver" &&
                  caregiverList && //for provider
                  lastList.map((arr, index1) => {
                    const options = arr.items
                      .map((patient) => ({
                        value: patient.patientId,
                        label: `${patient.firstName} ${patient.lastName}
                          ${patient.externalId
                            ? `, ID: ${patient.externalId}`
                            : ""
                          }${patient.patientStatus === "Released"
                            ? PATIENT_CONTROL[language].patient_list_options.released
                            : ""
                          }`,
                      }))
                      .sort((a, b) => a?.label.localeCompare(b?.label));
                    return (
                      <div className="mb-4" key={index1}>
                        <h5> {arr.caregiver}</h5>
                        <Select
                          options={options}
                          value={options.find(
                            (option) => option.value === selectedPatientCg
                          )}
                          placeholder={PATIENT_CONTROL[language].patient_list_options.select}
                          onChange={(selectedOption) => {
                            setSelectedPatientCg(selectedOption.value);
                            // handleSubmit(arr.items.find((patient) => patient.patientId === selectedOption.value));
                          }}
                        />
                        {arr.items
                          .sort((a, b) =>
                            a?.firstName?.localeCompare(b?.firstName)
                          )
                          .map((patient, index) => (
                            <Col key={patient.patientId}>
                              {patient.patientId === selectedPatientCg && (
                                <PillButton
                                  autoMargin
                                  key={patient.patientId}
                                  variant={
                                    patient.patientStatus === "Released"
                                      ? "gray"
                                      : patient === patient.patientId
                                        ? "info"
                                        : "secondary"
                                  }
                                  onClick={() => {
                                    handleSubmit(patient);
                                  }}
                                >
                                  {patient === patient.patientId ? (
                                    <img alt="Checked" src={Check} />
                                  ) : (
                                    ""
                                  )}
                                  {`${patient.firstName} ${patient.lastName}
                                ${patient.externalId
                                      ? `, ID: ${patient.externalId}`
                                      : ""
                                    }`}
                                </PillButton>
                              )}
                            </Col>
                          ))}
                      </div>
                    );
                  })}
              </Col>
            )}

            {user.userType != "caregiver" && therapistList && (
              <Col md={4}>
                <h5 className="mb-4">{PATIENT_CONTROL[language].patient_list.therapist}</h5>
                {user.userType != "caregiver" &&
                  therapistList && //for provider
                  lastList2.map((arr, index1) => {
                    const options = arr.items
                      .map((patient) => ({
                        value: patient.patientId,
                        label: `${patient.firstName} ${patient.lastName}
                        ${patient.externalId
                            ? `, ID: ${patient.externalId}`
                            : ""
                          }${patient.patientStatus === "Released"
                            ? PATIENT_CONTROL[language].patient_list_options.released
                            : ""
                          }`,
                      }))
                      .sort((a, b) => a?.label.localeCompare(b?.label));
                    return (
                      <div key={index1}>
                        <h5> {arr.caregiver}</h5>
                        <Select
                          options={options}
                          value={options.find(
                            (option) => option.value === selectedPatientTp
                          )}
                          placeholder={PATIENT_CONTROL[language].patient_list_options.select}
                          onChange={(selectedOption) => {
                            setSelectedPatientTp(selectedOption.value);
                            // handleSubmit(arr.items.find((patient) => patient.patientId === selectedOption.value));
                          }}
                        />
                        {arr.items
                          .sort((a, b) =>
                            a?.firstName?.localeCompare(b?.firstName)
                          )
                          .map((patient) => (
                            <Col key={patient.patientId}>
                              {patient.patientId === selectedPatientTp && (
                                <PillButton
                                  autoMargin
                                  variant={
                                    patient.patientStatus === "Released"
                                      ? "gray"
                                      : patient === patient.patientId
                                        ? "info"
                                        : "secondary"
                                  }
                                  onClick={() => {
                                    handleSubmit(patient);
                                  }}
                                >
                                  {patient === patient.patientId ? (
                                    <img alt="Checked" src={Check} />
                                  ) : (
                                    ""
                                  )}
                                  {`${patient.firstName} ${patient.lastName}
                                  ${patient.externalId
                                      ? `, ID: ${patient.externalId}`
                                      : ""
                                    }`}
                                </PillButton>
                              )}
                            </Col>
                          ))}
                      </div>
                    );
                  })}
              </Col>
            )}
          </Row>
        </ComponentWrapper>
        {user.userType == "caregiver" ? (
          <div style={{ fontSize: "1.5rem" }}>
            {PATIENT_CONTROL[language].add_patient[0]}
            <SmLinkButton>
              <Link
                to={{
                  pathname: "/RegisterPatient",
                  state: { fromPatientControl: true },
                }}
              >
                {PATIENT_CONTROL[language].add_patient[1]}
              </Link>
            </SmLinkButton>
          </div>
        ) : null}

        {user.userType == "Therapist" ? (
          <div style={{ fontSize: "1.2rem" }}>
            {PATIENT_CONTROL[language].add_patient[0]}
            <SmLinkButton>
              <Link
                to={{
                  pathname: "/RegisterPatientTherapist",
                  state: { fromPatientControl: true },
                }}
              >
                {PATIENT_CONTROL[language].add_patient[1]}
              </Link>
            </SmLinkButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PatientControl;
