// eslint-disable-next-line import/no-mutable-exports
let apiUrl;
const apiUrls = {
  production: "https://api.powerofpatients.com",
  development: "http://localhost:4242",
  test: "https://test-api.powerofpatients.com",
  developmentLan: `https://${window.location.hostname}`,
};

if (window.location.hostname === "localhost") {
  apiUrl = apiUrls.development;
} else if (window.location.hostname.startsWith("192.168")) {
  apiUrl = apiUrls.developmentLan;
} else if (window.location.hostname.startsWith("test")) {
  apiUrl = apiUrls.test;
} else {
  apiUrl = apiUrls.production;
}

export default apiUrl;
