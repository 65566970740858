import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { Form, Col, Row } from "react-bootstrap";

// hooks
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";

// components
import Sallie from "../Sallie";
import LabelledInput from "../LabelledInput";
import DescribeTBISchema from "./validations/describeTBISchema";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";

// api
import { createTBI } from "../../api/TBIRequests";
import customizeMessages from "../../lib/customizeMessages";

// translation
import {
  TBI_HEADER,
  TBI_SALLIE,
  TBI_DATE,
  TBI_DESCRIPTION,
  SAVE_AND_CONTINUE,
  TBI_NUMBER
} from "../../constants/OnboardingTranslation";

const RecentTBI1 = ({ user, setOnboardingPercent }) => {
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(DescribeTBISchema),
  });
  useEffect(() => {
    location.state?.fromPatientControl
      ? setOnboardingPercent(30)
      : setOnboardingPercent(20);
    setLoading(false);
  }, []);

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      data.dateOfTBI = format(new Date(data.dateOfTBI), "MM-dd-yyyy");
      await createTBI({ patientId: patient.patientId, data });
      setSubmitting(false);
      history.push("/RecentTBI2", {
        fromPatientControl: location.state?.fromPatientControl,
      });
    } catch (err) {
      console.log(err);
      history.push("/oops");
    }
  };

  const getPossibleMessages = () => ({
    mainHeader: {
      tbiPatient: TBI_HEADER[language].patient,
      caregiver: TBI_HEADER[language].caregiver,
    },
    sallieText: {
      tbiPatient: TBI_SALLIE[language].patient,
      caregiver: TBI_SALLIE[language].caregiver,
    },
    dateOfTbiLabel: {
      tbiPatient: TBI_DATE[language].patient,
      caregiver: TBI_DATE[language].caregiver,
    },
    addTbis: {
      tbiPatient:
        "You will have the option to add other " +
        "TBI&apos;s after you set up your account.",
      caregiver:
        "You will have the option to add other " +
        "TBI&apos;s after you set up your account.",
    },
    describeEventLabel: {
      tbiPatient: TBI_DESCRIPTION[language].patient.label,
      caregiver: TBI_DESCRIPTION[language].caregiver.label,
    },
    describeEventExtraInfo: {
      tbiPatient: TBI_DESCRIPTION[language].patient.info,
      caregiver: TBI_DESCRIPTION[language].caregiver.info,
    },
    describeEventPlaceholder: {
      tbiPatient: TBI_DESCRIPTION[language].patient.placeholder,
      caregiver: TBI_DESCRIPTION[language].caregiver.placeholder
    },
    totalTbiLabel: {
      tbiPatient: TBI_NUMBER[language].patient,
      caregiver: TBI_NUMBER[language].caregiver,
    },
  });

  const getMessage = customizeMessages({ user, getPossibleMessages });

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <>
          <div className="d-block">
            <OnboardingStepHeader text={getMessage("mainHeader")} />

            <Sallie text={getMessage("sallieText")} />
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-block">
              <Form.Row>
                <Col>
                  <LabelledInput
                    autoFocus
                    required
                    labelAs="h6"
                    name="dateOfTBI"
                    placeholder="MM/DD/YYYY"
                    type="date"
                    date-format="mm/dd/yyyy"
                    inputRef={register}
                    label={getMessage("dateOfTbiLabel")}
                    errors={errors}
                  />
                </Col>
                {/* hidden until we can implement, change to block when done */}
                <Col className="d-none align-items-end">
                  <div className="mb-3 ml-3">{getMessage("addTbis")}</div>
                </Col>
                {/* end hidden */}
              </Form.Row>
              <LabelledInput
                inputAs="textarea"
                required
                labelAs="h6"
                extraInfo={getMessage("describeEventExtraInfo")}
                name="describeEvent"
                placeholder={getMessage("describeEventPlaceholder")}
                type="text"
                inputRef={register}
                label={getMessage("describeEventLabel")}
                errors={errors}
                rows={3}
              />
              <LabelledInput
                name="totalTBI"
                label={getMessage("totalTbiLabel")}
                labelAs="h6"
                required
                type="number"
                inputRef={register}
                errors={errors}
                min={1}
              />
              <Row>
                <SubmitButton type="submit" disabled={submitting}>
                  {SAVE_AND_CONTINUE[language]} &gt;
                </SubmitButton>
              </Row>
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default RecentTBI1;
