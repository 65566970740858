import * as yup from "yup"

const DescribeTBISchema = yup.object().shape({
  dateOfTBI: yup
    .date()
    .required("Date of TBI is required")
    .typeError("Enter valid date")
    .max(new Date(), "Enter valid date"),
  describeEvent: yup.string().required("Description required"),
  totalTBI: yup
    .number()
    .integer("Number of TBI required")
    .required("Number of TBI required")
    .typeError("Enter a whole number")
    .min(1, "Enter a positive number")
})

export default DescribeTBISchema
