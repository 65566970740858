import React, { useContext } from "react";
import { ThemeContext, PreferredLanguageContext } from "../lib/main-context";
import { NO_TRACKING_DATA, TOP_TRIGGERS } from "../constants/DashboardTranslation";

import theme from "../index.scss";
import ReactECharts from 'echarts-for-react';

const TriggerBarChart = ({ data, userName, splitView }) => {
    const { dark } = useContext(ThemeContext);
    const { language } = useContext(PreferredLanguageContext);
    const calNumOfEachTriggerFactor = (data) => {
        const count = {};
        data.forEach((item) => {
            const elements = item.description.split(", ");
            elements.forEach((element) => {
                count[element] = (count[element] || 0) + 1;
            });
        });
        const result = Object.entries(count).map(([name, value]) => ({ name, value }));
        const sorted = result.sort((a, b) => b.value - a.value);
        const top5 = sorted.slice(0, 5);
        return top5
    }
    // [
    //   { name: "Phone", value: 1 },
    //   { name: "Low Temperature", value: 1 },
    //   { name: "TV", value: 1 },
    //   { name: "Tablet", value: 1 },
    //   { name: "Exercise", value: 1 }
    // ]

    const top5 = calNumOfEachTriggerFactor(data)
    const option = {
        title: {
            text: TOP_TRIGGERS[language],
            left: 'center',
            textStyle: {
                fontSize: '1.2rem',
                color: dark ? theme.darkModeText : theme.black,
            },
            subtext: top5.length == 0 ? NO_TRACKING_DATA[language][0] : `${splitView ? `(${userName})` : ""}`,
            subtextStyle: {
                fontSize: '1.3rem',
                color: dark ? theme.darkModeText : theme.primary,
            },
        },
        tooltip: {
            trigger: 'item',

        },
        xAxis: {
            type: 'value',
            axisLabel: {
                fontSize: 16, // Change the font size here
                color: dark ? theme.darkModeText : theme.black,
            },
        },
        grid: {
            left: '5%',   // Adjust the left padding here
            containLabel: true
        },
        yAxis: {
            type: 'category',
            axisLabel: {
                fontSize: 16, // Change the font size here
                color: dark ? theme.darkModeText : theme.black,
            },
            data: top5.map(item => item.name).reverse() // reverse to show top elements at top
        },
        series: [
            {
                data: top5.map(item => item.value).reverse(),
                type: 'bar'
            }
        ]
    };

    return (
        <ReactECharts option={option} style={{ minHeight: "25vh" }} />
    )
}

export default TriggerBarChart;
