import React, { useState, useContext, useEffect } from "react";
import { ThemeContext, PreferredLanguageContext } from "../../lib/main-context";
import { REFER_PATIENT_SELECTION } from "../../constants/DashboardTranslation";

import { useHistory, Redirect } from "react-router-dom";

import Col from "react-bootstrap/Col";

import Sallie from "../Sallie";
import theme from "../../index.scss";
import Spinner from "../../Components/LoadingSpinner";
import ComponentWrapper from "./ComponentWrapper";
import PillButton from "../StyledComponents/PillButton";

import Check from "../../images/checkWithinCircle.svg";

import { sendReferral } from "../../api/referralRequest";

const ReferPatientSelection = ({
  user,
  patientList,
  caregiverList,
  therapistList,
  setInputVisible,
}) => {
  const history = useHistory();
  const { dark } = useContext(ThemeContext);
  const { language } = useContext(PreferredLanguageContext);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState({
    content: "",
    type: "",
  });

  useEffect(() => {
    if (
      !(
        user.userType == "caregiver" ||
        user.userType == "provider" ||
        user.userType == "Therapist"
      )
    )
      history.push("/");
  }, []);

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/signIn",
        }}
      />
    );
  }

  const createItemList = (res, caregiverList) =>
    [...new Set(caregiverList?.map(({ caregiverName }) => caregiverName))].map(
      (caregiver) => ({
        caregiver,
        items: res.filter((item) => item.caregiverName === caregiver),
      })
    );

  const createItemList2 = (res, therapistList) =>
    [...new Set(therapistList?.map(({ caregiverName }) => caregiverName))].map(
      (caregiver) => ({
        caregiver,
        items: res.filter((item) => item.caregiverName === caregiver),
      })
    );

  const caregiverPatientList = createItemList(caregiverList, caregiverList);
  const therapistPatientList = createItemList2(therapistList, therapistList);

  const PatientItem = ({ patient }) => {
    const [email, setEmail] = useState("");
    const handleButtonClick = () => {
      if (selectedPatient?.patientId === patient.patientId) {
        setSelectedPatient(null);
      } else {
        setSelectedPatient(patient);
      }
    };

    const handleSubmit = async () => {
      setSubmitting(true);

      if (!email) {
        setIsInputEmpty(true);
        setSubmitting(false);
        return;
      }

      try {
        const updatedPatient = {
          ...user,
          ...selectedPatient,
          recipientEmail: email.toLowerCase(),
        };
        await sendReferral(updatedPatient);
        setIsInputEmpty(false);
        setMessage({ content: REFER_PATIENT_SELECTION[language].messages.sent, type: "success" });
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 404) {
          const handleInviteProvider = () => {
            setInputVisible(true);
            history.push("/", {
              fromReferPatient: true,
            });
          };

          setMessage({
            content: (
              <span>
                {REFER_PATIENT_SELECTION[language].messages.no_email[0]}
                <a href="#" onClick={handleInviteProvider}>
                  {REFER_PATIENT_SELECTION[language].messages.no_email[1]}
                </a>
                .
              </span>
            ),
            type: "error",
          });
        } else if (error.response && error.response.status === 402) {
          setMessage({
            content: REFER_PATIENT_SELECTION[language].messages.account_type,
            type: "error",
          });
        } else if (error.response && error.response.status === 401) {
          setMessage({ content: error.response.data.message, type: "error" });
        } else if (error.response && error.response.status === 500) {
          setMessage({
            content: REFER_PATIENT_SELECTION[language].messages.fail,
            type: "error",
          });
        } else {
          setMessage({
            content: REFER_PATIENT_SELECTION[language].messages.error,
            type: "error",
          });
        }
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <div
        key={patient.patientId}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PillButton
          autoMargin
          onClick={handleButtonClick}
          variant={
            selectedPatient?.patientId === patient.patientId
              ? "info"
              : "secondary"
          }
        >
          {selectedPatient?.patientId === patient.patientId && (
            <img alt="Checked" src={Check} />
          )}
          {`(${patient.firstName} ${patient.lastName}`}{" "}
          {patient.externalId && `ID: ${patient.externalId}`})
        </PillButton>
        {selectedPatient?.patientId === patient.patientId && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <span style={{ display: "inline" }}>{REFER_PATIENT_SELECTION[language].receiver_email}</span>
              <span className="required">*</span>
            </div>
            {isInputEmpty && (
              <div style={{ color: "red" }}>{REFER_PATIENT_SELECTION[language].enter_email}</div>
            )}
            {message.type == "error" && (
              <div className="required">{message.content}</div>
            )}
            {message.type == "success" && (
              <div style={{ color: "green" }}>{message.content}</div>
            )}
            <input
              type="email"
              style={{ width: "18rem" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {submitting ? (
              <Spinner />
            ) : (
              <button style={{ width: "18rem" }} onClick={handleSubmit}>
                {REFER_PATIENT_SELECTION[language].send_request}
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Sallie text={REFER_PATIENT_SELECTION[language].sallie} />
      <Col
        className={`d-flex flex-wrap align-items-end ${"justify-content-end"}`}
      >
        <h6
          className="pt-2 pb-2 mb-4"
          style={{
            width: "30%",
            borderRadius: "20px",
            backgroundColor: dark ? theme.darkModePrimary : "#3EB1A6",
            color: dark ? theme.darkModeText : "white",
            textAlign: "center",
            overflowWrap: "break-word",
            cursor: "pointer",
          }}
          onClick={() => history.push("/patientControl")}
        >
          {REFER_PATIENT_SELECTION[language].switch_patient}
        </h6>
      </Col>
      <div className="w-75 p-3 mx-auto">
        <ComponentWrapper>
          <h6 className="d-block">
            {patientList && patientList.length === 0
              ? REFER_PATIENT_SELECTION[language].no_patient
              : REFER_PATIENT_SELECTION[language].choose_patient}
          </h6>
          {patientList && <h5>{REFER_PATIENT_SELECTION[language].patient_list}</h5>}
          {/* // <Spinner animation="border" role="status">
                        //   <span className="sr-only">Loading...</span>
                        // </Spinner> */}
          {patientList && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {patientList
                .sort((a, b) => a?.firstName?.localeCompare(b?.firstName))
                .map((patient, index) => (
                  <PatientItem key={patient.patientId} patient={patient} />
                ))}
            </div>
          )}
          {user.userType != "caregiver" && caregiverList && (
            <h5>{REFER_PATIENT_SELECTION[language].caregiver_list}</h5>
          )}
          {user.userType != "caregiver" &&
            caregiverList && //for provider
            caregiverPatientList.map((arr, index1) => {
              return (
                <div key={index1}>
                  <h6> {arr.caregiver}</h6>
                  {arr.items
                    .sort((a, b) => a?.firstName?.localeCompare(b?.firstName))
                    .map((patient, index) => (
                      <PatientItem key={patient.patientId} patient={patient} />
                    ))}
                </div>
              );
            })}
          {user.userType != "caregiver" && therapistList && (
            <h5>{REFER_PATIENT_SELECTION[language].therapist_list}</h5>
          )}
          {user.userType != "caregiver" &&
            therapistList && //for provider
            therapistPatientList.map((arr, index1) => {
              return (
                <div key={index1}>
                  <h6> {arr.caregiver}</h6>
                  {arr.items
                    .sort((a, b) => a?.firstName?.localeCompare(b?.firstName))
                    .map((patient, index) => (
                      <PatientItem key={patient.patientId} patient={patient} />
                    ))}
                </div>
              );
            })}
        </ComponentWrapper>
      </div>
    </div>
  );
};

export default ReferPatientSelection;
