import React, { useContext } from 'react';
import { PreferredLanguageContext } from '../lib/main-context';
import { Form } from 'react-bootstrap';

const SelectField = ({ controlId, label, name, options, register, errors }) => {
    const { language } = useContext(PreferredLanguageContext);
    return (
        <Form.Group controlId={controlId}>
            <Form.Label>
                {label}
                <span className="required">*</span>
            </Form.Label>
            <Form.Control
                name={name}
                as="select"
                ref={register}
                isInvalid={errors[name]}
            >
                {/* <option value="">Select {label}</option> */}
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Control>
            {language !== 'spanish' &&
                <Form.Control.Feedback type="invalid">
                    {errors[name] && errors[name].message}
                </Form.Control.Feedback>
            }
        </Form.Group>
    );
};

export default SelectField;
