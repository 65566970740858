import * as yup from "yup";

const NotificationSchema = yup
  .object()
  .shape({
    emailAlert: yup.boolean(),
    hour: yup
      .number()
      .typeError("Please enter a valid number for the hour")
      .when('emailAlert', {
        is: true,
        then: yup
          .number()
          .typeError("Please enter a valid number for the hour")
          .required("Please enter the hour")
          .max(12, "Please enter a valid time")
          .min(1, "Please enter a valid time"),
        otherwise: yup.number().nullable(),
      }),
    monday: yup.boolean(),
    tuesday: yup.boolean(),
    wednesday: yup.boolean(),
    thursday: yup.boolean(),
    friday: yup.boolean(),
    saturday: yup.boolean(),
    sunday: yup.boolean(),
    firstWeek: yup.boolean(),
    secondWeek: yup.boolean(),
    thirdWeek: yup.boolean(),
    fourthWeek: yup.boolean(),
  })
  .test("dayOfWeekTest", null, (obj) => {
    if (obj.emailAlert) {
      if (
        !obj.monday &&
        !obj.tuesday &&
        !obj.wednesday &&
        !obj.thursday &&
        !obj.friday &&
        !obj.saturday &&
        !obj.sunday
      ) {
        return new yup.ValidationError(
          "Please check at least one checkbox for day of the week",
          null,
          "dayofweek"
        );
      } else {
        return true;
      }
    } else {
      return true;
    }
  })
  .test("weekOfMonthTest", null, (obj) => {
    if (obj.emailAlert) {
      if (
        !obj.firstWeek &&
        !obj.secondWeek &&
        !obj.thirdWeek &&
        !obj.fourthWeek &&
        !obj.fifthWeek
      ) {
        return new yup.ValidationError(
          "Please check at least one checkbox for week of the month",
          null,
          "weekOfMonth"
        );
      } else {
        return true;
      }
    } else {
      return true;
    }
  });

export default NotificationSchema;
