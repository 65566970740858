import React, { useEffect, useState, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { NOTIFICATION_SETTINGS } from "../../constants/AccountSettingTranslation";

import { Form } from "react-bootstrap/";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { format } from "date-fns";
import NotificationSchema from "../Onboarding/validations/notificationSchema";

import SubmitButton from "../StyledComponents/SubmitButton";

import stringifyDayOfWeek from "../../lib/stringifyDayOfWeek";
import stringifyWeekOfMonth from "../../lib/stringifyWeekOfMonth";

import { getTrackingPrefs, updateTrackingPrefs } from "../../api/auth";

import TimeInput from "../TimeInput";
import WeekInput from "../WeekInput";

const ChangeNotification = ({ setIsSuccess }) => {
  const { language } = useContext(PreferredLanguageContext);

  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [isEmailAlert, setIsEmailAlert] = useState(false);
  const [oldTz, setOldTz] = useState(undefined);
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(NotificationSchema),
  });

  useEffect(() => {
    setIsSuccess(undefined);
    getTrackingPrefs().then((result) => {
      if (result.data.email_alert == true) {
        const time = result.data.time_alert.slice(0, 2);
        const isAM = time < 12;
        const dayOfWeek = result.data.day_of_week.split(",");
        const weekOfMonth = result.data?.week_of_month?.split(",");
        setOldTz(result.data.timezone);
        setValue("emailAlert", result.data.email_alert);
        setValue("hour", isAM ? time : time - 12);
        setValue("partOfDay", isAM ? "AM" : "PM");
        setValue("monday", dayOfWeek[1] === "true" ? true : false);
        setValue("tuesday", dayOfWeek[2] === "true" ? true : false);
        setValue("wednesday", dayOfWeek[3] === "true" ? true : false);
        setValue("thursday", dayOfWeek[4] === "true" ? true : false);
        setValue("friday", dayOfWeek[5] === "true" ? true : false);
        setValue("saturday", dayOfWeek[6] === "true" ? true : false);
        setValue("sunday", dayOfWeek[0] === "true" ? true : false);
        if (weekOfMonth) {
          setValue("firstWeek", weekOfMonth[0] === "true" ? true : false);
          setValue("secondWeek", weekOfMonth[1] === "true" ? true : false);
          setValue("thirdWeek", weekOfMonth[2] === "true" ? true : false);
          setValue("fourthWeek", weekOfMonth[3] === "true" ? true : false);
          setValue("fifthWeek", weekOfMonth[4] === "true" ? true : false);
        } else {
          setValue("firstWeek", false);
          setValue("secondWeek", false);
          setValue("thirdWeek", false);
          setValue("fourthWeek", false);
          setValue("fifthWeek", false);
        }
        setIsEmailAlert(true);
      }
    });
  }, []);

  const getNewTimezone = (oldTimezone) => {
    const currTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (currTz) {
      return currTz;
    } else {
      return oldTimezone;
    }
  };

  const disableDayOfWeek = (e) => {
    if (e.target.checked) {
      setIsEmailAlert(true);
    } else {
      setIsEmailAlert(false);
    }
  };

  const onSubmit = (data) => {
    setSubmitting(true);
    setShow(false);
    let notificationData = { emailAlert: data.emailAlert };

    let time;
    if (!data.hour && !data.partOfDay) {
      time = format(
        new Date(
          0,
          0,
          0,
          4,//defaultValue for unsubscribe
          0
        ),
        "HH:mm"
      );
    } else {
      time = format(
        new Date(
          0,
          0,
          0,
          data.partOfDay === "AM" ? data.hour : data.hour + 12,
          0
        ),
        "HH:mm"
      );
    }

    notificationData.timeAlert = time;
    const timezone = getNewTimezone(oldTz);
    const dayOfWeek = stringifyDayOfWeek(data);
    const weekOfMonth = stringifyWeekOfMonth(data);
    notificationData.dayOfWeek = dayOfWeek;
    notificationData.weekOfMonth = weekOfMonth;
    notificationData.tz = timezone;

    updateTrackingPrefs(notificationData)
      .then(() => {
        setIsSuccess(true);
        setSubmitting(false);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
        setSubmitting(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="email">
        <Form.Check
          name="emailAlert"
          ref={register}
          type="checkbox"
          label={NOTIFICATION_SETTINGS[language].email}
          onChange={disableDayOfWeek}
        // disabled={isNoData}
        />
      </Form.Group>
      <WeekInput
        errors={errors}
        checked={true}
        register={register}
        label={NOTIFICATION_SETTINGS[language].reminder}
        disableStatus={!isEmailAlert}

      />

      <TimeInput
        errors={errors}
        checked={true}
        register={register}
        label={NOTIFICATION_SETTINGS[language].reminder}
        disableStatus={!isEmailAlert}
      />

      <SubmitButton disabled={submitting} type="submit">
        {NOTIFICATION_SETTINGS[language].save}
      </SubmitButton>
      {show ? <div style={{ color: "red", textAlign: "right" }}>{NOTIFICATION_SETTINGS[language].success}</div> : null}
    </Form>
  );
}

export default ChangeNotification;
