import React, { useState, useEffect, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { EMAIL_SENT, LOGIN, PAYNOW } from "../../constants/OnboardingTranslation";

import { useHistory } from "react-router-dom";

import OnboardingStepHeader from "./OnboardingStepHeader";
import Sallie from "../Sallie";
import SubmitButton from "../StyledComponents/SubmitButton";

const EmailSent = ({ setOnboardingPercent, user }) => {
  const { language } = useContext(PreferredLanguageContext);
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setOnboardingPercent(90);
  }, []);

  const clickHandler = () => {
    setSubmitting(true);
    history.push("/");
  };

  const clickHandler2 = () => {
    setSubmitting(true);
    history.push("/PaymentPage");
  };

  let SallieMsg = `${EMAIL_SENT[language].email_sent_one} ${user.email} ${EMAIL_SENT[language].email_sent_two}`;
  return (
    <>
      <OnboardingStepHeader text={EMAIL_SENT[language].header} />
      <Sallie text={SallieMsg} />
      <SubmitButton
        disabled={submitting}
        onClick={user.userType !== "Therapist" ? clickHandler : clickHandler2}
      >
        {user.userType !== "Therapist" ? LOGIN[language] : PAYNOW[language]}
      </SubmitButton>
    </>
  );
};

export default EmailSent;
