import React, { useContext } from "react"
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { SYMPTOM_SELECTOR } from "../../constants/OnboardingTranslation";
import { PAUSE } from "../../constants/DashboardTranslation";

import { Row, Col } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import styled from "styled-components"

import {
    removeTrackedFactors,
    postPausedList,
} from "../../api/TBIRequests";

const Container = styled.div`
  // width: 280px;
  margin: 10px;
  h6 {
    text-align: center;
  }
  ul {
    font-weight: normal;
    font-size: 15px;
    margin: 10px;
  }
`

const UITrackingList = ({ symptoms, pauseList, setPauseList, pausedFactorObj, setPausedFactorObj, setTopSymptoms, title }) => {
    const { patient } = useContext(PatientContext);
    const { language } = useContext(PreferredLanguageContext);

    const addPauseFactor = (newone) => {
        let newParsedFactors = [];
        if (pauseList) {
            if (!pauseList.includes(newone)) {
                newParsedFactors = [...pauseList, newone];
                const objWithFactor = symptoms?.find((symptom) => symptom.factor === newone);
                const newPausedFactorObj = [...pausedFactorObj, objWithFactor]
                setPausedFactorObj(newPausedFactorObj)
            } else {
                newParsedFactors = [...pauseList];
            }
        } else {
            newParsedFactors = [newone];
        }
        setPauseList(newParsedFactors);
        postPausedList({
            patientId: patient.patientId,
            data: [[newone]],
        }).catch(() => {
            console.log("postPausedList error");
        })
    }

    const messages = {
        cognitive: `${SYMPTOM_SELECTOR[language].category.cognitive}: `,
        sleep: `${SYMPTOM_SELECTOR[language].category.sleep}: `,
        physical: `${SYMPTOM_SELECTOR[language].category.physical}: `,
        emotional: `${SYMPTOM_SELECTOR[language].category.emotional}: `,
        speech: `${SYMPTOM_SELECTOR[language].category.speech}: `,
        vision: `${SYMPTOM_SELECTOR[language].category.vision}: `,
    };

    const removeTracking = (s) => {
        // symptoms
        let newTopSymptoms = [];
        symptoms.forEach((sy) => {
            if (sy.factor != s) {
                newTopSymptoms.push(sy);
            }
        })
        setTopSymptoms(newTopSymptoms);
        removeTrackedFactors({
            patientId: patient.patientId,
            data: [s],
        }).catch(() => {
            console.log("removeTrackedFactors error");
        })
    }

    const uniqueSymptoms = [];
    const factors = new Set();

    for (const symptom of symptoms) {
        if (!factors.has(symptom.factor)) {
            factors.add(symptom.factor);
            uniqueSymptoms.push(symptom);
        }
    }

    // Create a Set to store unique subcategories
    const uniqueSubcategories = new Set();

    // Filter and populate the Set with unique subcategories
    uniqueSymptoms.forEach((symptom) => {
        uniqueSubcategories.add(symptom.subcategory);
    });

    // Convert the Set to an array (if needed)
    const uniqueSubcategoryArray = Array.from(uniqueSubcategories);

    const colors = {
        cognitive: "#edebf3",
        sleep: "#ffdadb",
        physical: "#ffdcaf",
        emotional: "#fff3c4",
        speech: "#cef3c4",
        vision: "#c1fdf7",

        // for trigger
        wellness: "#dab7ec",
        travel: "#ffdadb",
        "screen time": "#ffdcaf",
        environmental: "#fff3c4",
        Dietary: "#cef3c4",
        situational: "#c1fdf7",

        // for therapies
        Alternative: "#dab7ec",
        Applied: "#ffdadb",
        Chiropractic: "#ffdcaf",
        Vision: "#fff3c4",
        "Mental Health": "#c1fdf7",

        Other: "#f4f3ee",
    };
    const tabs = {
        cognitive: SYMPTOM_SELECTOR[language].category.cognitive,
        sleep: SYMPTOM_SELECTOR[language].category.sleep,
        physical: SYMPTOM_SELECTOR[language].category.physical,
        emotional: SYMPTOM_SELECTOR[language].category.emotional,
        speech: SYMPTOM_SELECTOR[language].category.speech,
        vision: SYMPTOM_SELECTOR[language].category.vision,
    };

    return (
        <Container>
            <h6>{title}</h6>
            <Tab.Container id="left-tabs-example" defaultActiveKey="cognitive">
                <Row style={{ marginTop: "0.5rem", fontWeight: "normal", fontSize: "15px" }}>
                    <Col sm={3} style={{ paddingRight: '0px' }}>
                        <Nav fill justify variant="tabs" className="flex-column">
                            {uniqueSubcategoryArray.map((subcategory, i) => (
                                <Nav.Item key={i}
                                >
                                    <Nav.Link className="navLink" eventKey={subcategory}
                                        style={{
                                            background: `${colors[subcategory]}`,
                                            border: '1px solid #106995',

                                        }}> {tabs[subcategory]}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>
                    <Col sm={7} style={{
                        marginLeft: '1rem', paddingRight: '0px'
                    }}>
                        <Tab.Content>
                            {uniqueSubcategoryArray.map((subcategory, i) => (
                                <Tab.Pane eventKey={subcategory} key={i}>
                                    <h6 className="d-inline">{messages[subcategory]}</h6>
                                    <ul>
                                        {uniqueSymptoms
                                            .filter((symptom) => symptom.subcategory === subcategory)
                                            .map((filteredSymptom, j) => (
                                                <li key={j}>
                                                    {filteredSymptom.factor}{" "}
                                                    <button
                                                        onClick={() => {
                                                            addPauseFactor(filteredSymptom.factor);
                                                            removeTracking(filteredSymptom.factor);
                                                        }}
                                                        style={{ background: '#da5033' }}
                                                    >
                                                        {PAUSE[language]}
                                                    </button>
                                                </li>
                                            ))}
                                    </ul>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    )
}

export default UITrackingList
