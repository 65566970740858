import React, { useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { SUGGESTION_BOX } from "../../constants/DashboardTranslation";

import { useHistory } from "react-router-dom";
import ComponentWrapper from "./ComponentWrapper";
import SubmitButton from "../StyledComponents/SubmitButton";

const SuggestionBox = () => {
  const { language } = useContext(PreferredLanguageContext);
  const history = useHistory();
  const clickHandler = () => {
    history.push("/help");
  };
  return (
    <ComponentWrapper>
      <div className="p-2">
        {SUGGESTION_BOX[language].label}
      </div>
      <SubmitButton onClick={clickHandler}>{SUGGESTION_BOX[language].button}</SubmitButton>
    </ComponentWrapper>
  );
};

export default SuggestionBox;
