import React, { useState, useContext } from "react";
import { PreferredLanguageContext } from "../lib/main-context";
import { CHOOSE, OTHER } from "../constants/OnboardingTranslation";
import MedicalPillButton from "./StyledComponents/MedicalPillButton";

const StrokeListSelecter = ({
  category,
  list,
  maxSelectAmount,
  isTherapist,
  selectedList,
  toggleFromSelectedList, // For Therapist and caregiver register page, users are only allowed to selcet one option over all list.
  selectForTherapist,
  selectOne,
  addOther,
  index,
  noOther,
  noSuffix,
  message,
}) => {
  const { language } = useContext(PreferredLanguageContext);
  // 'new item' is the item that will be appended to the returned data
  // if a user adds to 'other' field.
  const [newItem, setNewItem] = useState("");
  const naItems = ["N/A", "n/a", "NA", "na"];
  // suffix shows the user how many to choose
  let suffix;
  if (!noSuffix) {
    if (!maxSelectAmount) {
      suffix = ` (${CHOOSE[language].choose_all}) `;
    } else {
      suffix = ` (${CHOOSE[language].choose} ${maxSelectAmount}) `;
    }

    if (isTherapist) {
      suffix = "";
    }
  }

  const handleChange = (event) => {
    setNewItem(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (
      event.key === "Enter" ||
      (!event.currentTarget.contains(document.activeElement) &&
        newItem !== "" &&
        !naItems.includes(newItem))
    ) {
      const formattedItem = addOther(newItem, index);
      if (isTherapist) {
        selectForTherapist(formattedItem, category);
      } else {
        if (maxSelectAmount === 1) {
          selectOne(formattedItem, category);
        } else {
          toggleFromSelectedList(
            formattedItem,
            selectedList,
            category,
            maxSelectAmount
          );
        }
      }

      setNewItem("");
    }
  };

  return (
    <div
      className="listSelector"
      style={
        {
          // height: "20rem",
          // overflow: 'scroll',
        }
      }
    >
      <div className="mb-2">
        <h6 className="d-inline">{message}</h6>
        <em className="d-inline">{suffix}</em>
      </div>
      <div className="justify-content-left">
        {list?.map((selectableItem) => (
          <div key={selectableItem + 0}>
            <MedicalPillButton
              onClick={() =>
                maxSelectAmount === 1
                  ? selectOne(selectableItem, category)
                  : isTherapist
                    ? selectForTherapist(selectableItem, category)
                    : toggleFromSelectedList(
                      selectableItem,
                      selectedList,
                      category,
                      maxSelectAmount
                    )
              }
              variant={
                // selectedList.includes(selectableItem) ? "info" : "secondary"
                "light"
              }
              key={selectableItem}
              style={{ color: "black", border: " white", textAlign: "left" }}
            >
              {selectedList?.includes(selectableItem) ? (
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <title>icon/action/check_ccircle_24px</title>
                  <defs>
                    <path
                      d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
                      id="path-1"
                    ></path>
                  </defs>
                  <g
                    id="icon/action/check_ccircle_24px"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <mask id="mask-2" fill="white">
                      <use xlinkHref="#path-1"></use>
                    </mask>
                    <use
                      id="-↳Color"
                      fill={"#106995"}
                      xlinkHref="#path-1"
                    ></use>
                  </g>
                </svg>
              ) : (
                <svg width="24px" height="24px" viewBox="0 0 22 22">
                  <circle
                    cx="11"
                    cy="11"
                    r="9.5"
                    stroke="grey"
                    strokeWidth="1"
                    fill="white"
                  />
                </svg>
              )}{" "}
              {selectableItem}
            </MedicalPillButton>
          </div>
        ))}
      </div>
      {noOther ? (
        ""
      ) : (
        <label htmlFor={`${category}-other`} className="w-100">
          {OTHER[language]}: &nbsp;
          <input
            value={newItem}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleKeyPress}
            aria-label="Type the name of a new item and press enter to add a button to the list above"
            id={`${category}-other`}
            style={{
              border: "0",
              outline: "0",
              background: "transparent",
              borderBottom: "2px solid black",
              width: "33%",
            }}
            name="other"
            placeholder={OTHER[language]}
          />
        </label>
      )}
    </div>
  );
};

export default StrokeListSelecter;
