import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { GOALS, EDIT } from "../../constants/DashboardTranslation";

import { Link } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import ComponentWrapper from "./ComponentWrapper";
import LoadingSpinner from "../LoadingSpinner";
import EditIcon from "../../images/edit.svg";

import { getGoals } from "../../api/TBIRequests";

const GoalList = ({ userType }) => {
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  const [goals, setGoals] = useState(undefined);

  useEffect(() => {
    if (patient) {
      // Set up a flag to track whether the component is still mounted.
      let isComponentMounted = true;

      getGoals(patient.patientId)
        .then((result) => {
          if (isComponentMounted) {
            // Only update state if the component is still mounted.
            setGoals(result.data[0]);
          }
        })
        .catch((err) => {
          if (isComponentMounted) {
            console.log(err);
          }
        });

      // Cleanup function to set isComponentMounted to false when unmounted
      return () => {
        isComponentMounted = false;
      };
    }
  }, [patient]);


  return (
    <ComponentWrapper>
      {goals ? (
        <div>
          <Row>
            <Col md={4}>
              <h6>{GOALS[language]}</h6>
            </Col>
            {userType !== "provider" &&
              <Col md={{ span: 3, offset: 5 }}>
                <Link to="/accountSetting/setGoals">
                  <img src={EditIcon} alt="edit icon" className="editIcon" />
                  {EDIT[language]}
                </Link>
              </Col>
            }
          </Row>
          <div className="goalList">
            <Row className="goalItem">
              <Col sm={1}>1. </Col> <Col>{goals.first_goal}</Col>
            </Row>
            <Row className="goalItem">
              <Col sm={1}>2. </Col> <Col>{goals.second_goal}</Col>
            </Row>
            <Row className="goalItem">
              <Col sm={1}>3. </Col> <Col>{goals.third_goal}</Col>
            </Row>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </ComponentWrapper>
  );
};

export default GoalList;
