////////////////////////
// AccountSetting.js //
///////////////////////
export const SALLIE_SETTINGS = {
    english: {
        default: 'Change your account settings here! Click on one of the links in the box below.',
        notification: "I'm scheduled to come get you every day.",
        goals: 'Why do you want to track your symptoms?',
        success: 'The change has saved successfully!',
        error: "Oops! Something went wrong and we couldn't save your change. Please try again."
    },
    spanish: {
        default: '¡Cambia la configuración de tu cuenta aquí! Haz clic en uno de los enlaces en el cuadro a continuación.',
        notification: "Estoy programada para buscarte todos los días.",
        goals: '¿Por qué quieres realizar un seguimiento de tus síntomas?',
        success: '¡El cambio se ha guardado con éxito!',
        error: "¡Vaya! Algo salió mal y no pudimos guardar tu cambio. Por favor, inténtalo de nuevo."
    }
}

export const SETTINGS = {
    english: {
        notification: 'Notification Preferences',
        goals: 'Setting Goals'
    },
    spanish: {
        notification: 'Preferencias de notificaciones',
        goals: 'Establecer metas'
    }
}

////////////////////////////
// ChangeNotification.js //
///////////////////////////
export const NOTIFICATION_SETTINGS = {
    english: {
        email: 'Email',
        reminder: 'Set your reminder time',
        save: 'Save',
        success: 'Successfully Changed!'
    },
    spanish: {
        email: 'Correo electrónico',
        reminder: 'Ponga su tiempo de recordatorio',
        save: 'Guardar',
        success: '¡Cambiado exitosamente!'
    }
}

/////////////////////
// SettingGoals.js //
/////////////////////
export const GOALS_SETTINGS = {
    english: {
        label: 'Please include a goal for your tracking:',
        first_goal: '1st Goal',
        second_goal: '2nd Goal',
        third_goal: '3rd Goal',
        save: 'Save'
    },
    spanish: {
        label: 'Por favor, incluya un objetivo para su seguimiento:',
        first_goal: '1er Objetivo',
        second_goal: '2do Objetivo',
        third_goal: '3er Objetivo',
        save: 'Guardar'
    }
}