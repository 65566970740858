/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import { PatientContext } from "../../lib/main-context";
import { useHistory, useLocation } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Sallie from "../Sallie";
import ListSelector from "../ListSelector";
import useListSelector from "../../lib/useListSelector";
import OnboardingStepHeader from "./OnboardingStepHeader";
import SubmitButton from "../StyledComponents/SubmitButton";

import { postTrackedFactors } from "../../api/TBIRequests";

const SelectTopTriggers = ({ setOnboardingPercent }) => {
  const { patient } = useContext(PatientContext);

  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const { forwardData } = location.state || { forwardData: undefined };

  const [returnedData, setReturnedData] = useState([]);

  const {
    toggleFromSelectedList,
    switchSingleSelection,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const createItemList = () => {
    return [
      {
        category: "data",
        items: Object.values(forwardData).flat(),
        maxSelectAmount: "3",
      },
    ];
  };
  useEffect(() => {
    if (!forwardData) {
      // api call
    } else {
      const itemList = createItemList();
      initialize(itemList);
      setReturnedData(itemList);
    }
    setLoading(false);
    setOnboardingPercent(70);
  }, [forwardData]);

  const processDataForSending = () => {
    return forwardData.processedData
      .filter((item) => selectedData.data.flat().includes(item.factor))
      .map((item) => ({
        factor: item.factor,
        category: item.category,
        subcategory: item.subcategory,
      }));
  };

  const handleSubmit = () => {
    const mappedData = processDataForSending();
    postTrackedFactors({
      patientId: patient.patientId,
      data: mappedData,
    })
      .then(() => history.push("/notificationPrefs"))
      .catch(() => history.push("/oops"));
  };

  return (
    <>
      {loading ? (
        "Loading..."
      ) : (
        <>
          <OnboardingStepHeader text="Step 7: Top Triggers" />{" "}
          <Sallie text="Pick the Top 3 Triggers you would to track" />
          {returnedData.map((itemList, i) => (
            <ListSelector
              key={itemList.category}
              category={itemList.category}
              message={
                "Select the Top 3 Triggers you would like to track from the list below."
              }
              list={itemList.items}
              index={i}
              selectedList={selectedData[itemList.category]}
              toggleFromSelectedList={toggleFromSelectedList}
              selectOne={switchSingleSelection}
              maxSelectAmount={itemList.maxSelectAmount}
              noOther
              noSuffix
            />
          ))}
          <Row>
            <SubmitButton onClick={handleSubmit}>
              Save & Continue &gt;
            </SubmitButton>
          </Row>
        </>
      )}
    </>
  );
};

export default SelectTopTriggers;
