import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { STROKE_HEADER, STROKE_SALLIE, STROKE_DESCRIPTION, STROKE_DATE, SAVE_AND_CONTINUE, STROKE_NUMBER } from "../../constants/OnboardingTranslation";
import { Form, Col, Row } from "react-bootstrap";

// hooks
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";

// components
import Sallie from "../Sallie";
import LabelledInput from "../LabelledInput";
import DescribeStrokeSchema from "./validations/describeStrokeSchema";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";

// api
import { createStroke } from "../../api/TBIRequests";
import customizeMessages from "../../lib/customizeMessages";

const RecentStroke1 = ({ user, setOnboardingPercent }) => {
    const { patient } = useContext(PatientContext);
    const { language } = useContext(PreferredLanguageContext);

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(DescribeStrokeSchema),
    });

    useEffect(() => {
        location.state?.fromPatientControl
            ? setOnboardingPercent(30)
            : setOnboardingPercent(20);
        setLoading(false);
    }, []);

    const onSubmit = async (data) => {
        setSubmitting(true);
        try {
            data.stroke_incident_date = format(new Date(data.stroke_incident_date), "MM-dd-yyyy");
            await createStroke({ patientId: patient.patientId, data });
            setSubmitting(false);
            history.push("/RecentStroke2", {
                fromPatientControl: location.state?.fromPatientControl,
            });
        } catch (err) {
            console.log(err);
            history.push("/oops");
        }
    };

    const getPossibleMessages = () => ({
        mainHeader: {
            Stroke: STROKE_HEADER[language].patient,
            caregiver: STROKE_HEADER[language].caregiver,
        },
        sallieText: {
            Stroke: STROKE_SALLIE[language].patient,
            caregiver: STROKE_SALLIE[language].caregiver,
        },
        dateOfTbiLabel: {
            Stroke: STROKE_DATE[language].patient,
            caregiver: STROKE_DATE[language].caregiver,
        },
        addTbis: {
            tbiPatient:
                "You will have the option to add other " +
                "TBI&apos;s after you set up your account.",
            caregiver:
                "You will have the option to add other " +
                "TBI&apos;s after you set up your account.",
        },
        describeEventLabel: {
            Stroke: STROKE_DESCRIPTION[language].patient.label,
            caregiver: STROKE_DESCRIPTION[language].caregiver.label,
        },
        describeEventExtraInfo: {
            Stroke: STROKE_DESCRIPTION[language].patient.info,
            caregiver: STROKE_DESCRIPTION[language].caregiver.info,
        },
        describeEventPlaceholder: {
            // tbiPatient:
            //     "Example: I was on a ladder fixing my gutter when I fell " +
            //     "headfirst onto the ground. I sat up and experienced...",
            // caregiver:
            //     `Example: The patient was on a ladder fixing ` +
            //     `${getPronoun("his")} gutter ` +
            //     `when ${getPronoun("he")} fell headfirst onto ` +
            //     `the ground. The patient sat up and experienced...`,
        },
        totalTbiLabel: {
            Stroke: STROKE_NUMBER[language].patient,
            caregiver: STROKE_NUMBER[language].caregiver,
        },
    });

    const getMessage = customizeMessages({ user, getPossibleMessages });

    return (
        <>
            {loading ? (
                "Loading..."
            ) : (
                <>
                    <div className="d-block">
                        <OnboardingStepHeader text={getMessage("mainHeader")} />

                        <Sallie text={getMessage("sallieText")} />
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="d-block">
                            <Form.Row>
                                <Col>
                                    <LabelledInput
                                        autoFocus
                                        required
                                        labelAs="h6"
                                        name="stroke_incident_date"
                                        placeholder="MM/DD/YYYY"
                                        type="date"
                                        date-format="mm/dd/yyyy"
                                        inputRef={register}
                                        label={getMessage("dateOfTbiLabel")}
                                        errors={errors}
                                    />
                                </Col>
                                {/* hidden until we can implement, change to block when done */}
                                <Col className="d-none align-items-end">
                                    <div className="mb-3 ml-3">{getMessage("addTbis")}</div>
                                </Col>
                                {/* end hidden */}
                            </Form.Row>
                            <LabelledInput
                                inputAs="textarea"
                                required
                                labelAs="h6"
                                extraInfo={getMessage("describeEventExtraInfo")}
                                name="describeEvent"
                                placeholder={getMessage("describeEventPlaceholder")}
                                type="text"
                                inputRef={register}
                                label={getMessage("describeEventLabel")}
                                errors={errors}
                                rows={3}
                            />
                            <LabelledInput
                                name="total_stroke"
                                label={getMessage("totalTbiLabel")}
                                labelAs="h6"
                                required
                                type="number"
                                inputRef={register}
                                errors={errors}
                                min={1}
                            />
                            <Row>
                                <SubmitButton type="submit" disabled={submitting}>
                                    {SAVE_AND_CONTINUE[language]} &gt;
                                </SubmitButton>
                            </Row>
                        </div>
                    </Form>
                </>
            )}
        </>
    );
};

export default RecentStroke1;

