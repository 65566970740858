import { format } from "date-fns";
import theme from "../../index.scss";

const LineChartOptions = {
  spanGaps: true,
  title: {
    display: true,
    text: "Month in Review",
    fontFamily: "quicksand",
    fontStyle: "bold",
  },
  scales: {
    xAxes: [
      {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "dddd",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
          labelString: "Intensity Level",
        },
      },
    ],
  },
  hover: {
    animationDuration: 0,
  },
  animation: {
    duration: 1,
    onComplete: function () {
      // adding trigger count for each day if any. It will be showing on top of the most highest point.
      var chartInstance = this.chart,
        ctx = chartInstance.ctx;

      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";
      ctx.fillStyle = theme.primary;
      ctx.font = "1rem Arial";
      const triggerIndex = this.data.datasets.length - 1;
      const dataset = this.data.datasets[triggerIndex].data;
      const meta = chartInstance.controller.getDatasetMeta(triggerIndex);
      meta.data.forEach(function (bar, index) {
        var data = dataset[index].y;
        if (data) {
          let triggers = data.desc.split(",");
          ctx.fillText(`${triggers.length}`, bar._model.x, bar._model.y - 5);
        }
      });
    },
  },
  tooltips: {
    mode: "index",
    intersect: false,
    position: "average",
    callbacks: {
      label: (item, data) => {
        // this is for symptom notes.
        // when the user hover over the y index of each date, this will run.
        let pointData = data.datasets[item.datasetIndex].data[item.index].y;
        if (pointData) {
          if (typeof pointData.y !== "number") {
            // they the user have data for the day, symptom note is in pointData.y.desc
            // else just return pointData.desc which is empty string.
            return pointData.y.desc;
          }
          return pointData.desc;
        }
      },
      afterBody: (item, data) => {
        // this is for additional notes.
        // when the user hover over the y index of each date, this will run.
        // each additional notes for the day is appended under label (symptom notes) as an ordered list.
        if (data.notes) {
          let itemDate = format(
            new Date(data.datasets[0].data[item[0].index].x),
            "yyyy-MM-dd"
          );
          let additionalNotes = data.notes.filter(
            (note) => note.additional_notes_date.slice(0, 10) === itemDate
          );
          let combinedNote = "";
          additionalNotes.forEach(
            (note, idx) => (combinedNote += `${idx + 1}. ` + note.note + "\n")
          );
          return combinedNote;
        } else {
          return "";
        }
      },
    },
  },
};
export default LineChartOptions;
