const triggerWithSetVal = (data) => {
  let triggers = data?.filter(({ category }) => category === "SDOH");
  let triggersWithVal = [];

  triggers && triggers.forEach((trigger, index) => {
    const sameDayTrigger = triggers?.filter(
      ({ symptom_date }) => symptom_date === trigger.symptom_date
    );
    let sameDayTriggerCombined = [];
    sameDayTrigger.forEach((currentTrigger) =>
      sameDayTriggerCombined.push(currentTrigger.factor)
    );

    //to avoid duplicates on sameday, push the combined trigger list for the day only once.
    if (sameDayTriggerCombined[0] === trigger.factor) {
      let symptomsOnSameDay = data?.filter(
        (factor) =>
          factor.symptom_date === trigger.symptom_date &&
          factor.category === "medical"
      );
      let biggestVal = 0;
      symptomsOnSameDay.forEach((factor) => {
        if (factor.severity > biggestVal) {
          biggestVal = factor.severity;
        }
      });

      triggersWithVal.push({
        symptom_date: trigger.symptom_date,
        severity: biggestVal + 5,
        description: sameDayTriggerCombined.join(", "),
      });
    }
  });

  return triggersWithVal;
};

export default triggerWithSetVal;
