import React, { useState, useEffect, useContext } from "react";
import { PreferredLanguageContext } from "../../lib/main-context";
import { PATIENT_INFO, PRIMARY_DIAGNOSIS, NAME, GENDER, DOB, CHOOSE, CONFIRM, NEW_OR_CURRENT_PATIENT, COMMENTS } from "../../constants/OnboardingTranslation";

// Hooks
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import useListSelector from "../../lib/useListSelector";
import StrokeListSelecter from "../StrokeListSelecter";
import { yupResolver } from "@hookform/resolvers";

// Bootstrap
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

// Validation Rules
import PatientSchema from "./validations/registerPatientValidation2";

import { getPatientList, registerPatient2 } from "../../api/auth";
import { createGoals, getPatientTypes } from "../../api/TBIRequests";
// Components
import Sallie from "../Sallie";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";

const PatientInfo = ({
  setOnboardingPercent,
  setPatientList,
  user,
  setPatient,
}) => {
  const { language } = useContext(PreferredLanguageContext);
  const types = [
    {
      category: "TBI/Concussion",
      items: PRIMARY_DIAGNOSIS[language]["TBI/Concussion"].items,
    },
    {
      category: "Dementia",
      items: PRIMARY_DIAGNOSIS[language].Dementia.items,
    },
    {
      category: "Stroke",
      items: PRIMARY_DIAGNOSIS[language].Stroke.items,
    },
    {
      category: "Other",
      items: PRIMARY_DIAGNOSIS[language].Other.items,
    },
  ];

  const [returnedData, setReturnedData] = useState([]);
  const {
    toggleFromSelectedList,
    switchSingleSelection,
    selectForTherapist,
    addOther,
    selectedData,
    initialize,
  } = useListSelector({
    setInitialData: setReturnedData,
    initialData: returnedData,
  });

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    location.state?.fromPatientControl
      ? setOnboardingPercent(20)
      : setOnboardingPercent(15);
    setReturnedData(types);
    initialize(types);
  }, [language]);

  const [isLoading, setIsLoading] = useState(false);
  const [patientTypes, setPatientTypes] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(PatientSchema),
  });

  useEffect(() => {
    getPatientTypes().then((res) => {
      const { data } = res;
      setPatientTypes(data);
    });
  }, []);

  const onSubmit = async (data) => {
    if (
      selectedData[PRIMARY_DIAGNOSIS[language]["TBI/Concussion"].category] &&
      selectedData[PRIMARY_DIAGNOSIS[language]["TBI/Concussion"].category].length === 0 &&
      selectedData[PRIMARY_DIAGNOSIS[language].Dementia.category].length === 0 &&
      selectedData[PRIMARY_DIAGNOSIS[language].Stroke.category].length === 0 &&
      selectedData[PRIMARY_DIAGNOSIS[language].Other.category].length === 0
    ) {
      setErrorMessage(true);
      return;
    }

    for (const key in selectedData) {
      if (selectedData[key].length > 0) {
        data["patientType"] = key;
        data["patientSubType"] = selectedData[key][0];
      }
    }
    data.patientIdForPatientInviteTP =
      location.state?.patientIdForPatientInviteTP ?? "";
    try {
      setIsLoading(true);
      const responsePatientData = await registerPatient2(data);
      setPatient(responsePatientData.data);
      await createGoals({ patientId: responsePatientData.data.patientId });
      const getPatientListResponse = await getPatientList();
      setPatientList(getPatientListResponse.data);
      // ["Brain Stem", "Embolic", "Hemorrhagic", "Thrombotic", "TIA", "Unknown Cause"].includes(data.status.split(":")[1])
      if ("Stroke" == data.patientType) {
        history.push("/RecentStroke1", {
          fromPatientControl: location.state?.fromPatientControl,
        });
      } else if ("TBI/Concussion" == data.patientType) {
        history.push("/RecentTBI1", {
          fromPatientControl: location.state?.fromPatientControl,
        });
      } else {
        history.push("/RecentCondition1", {
          fromPatientControl: location.state?.fromPatientControl,
        });
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
      history.push("/oops");
    }
  };

  const SallieMsg = PATIENT_INFO[language].sallie;

  // Taken from Aut henticatedRoute, patient is still null but user should be set
  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/signIn",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <>
      <OnboardingStepHeader text={PATIENT_INFO[language].header} />
      <Sallie text={SallieMsg} />
      <Form
        autoComplete="on"
        action="/post"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Row>
          <Col md={6}>
            <LabelledInput
              name="firstName"
              required
              label={NAME[language].first}
              autoComplete="given-name"
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <LabelledInput
              name="lastName"
              autoComplete="family-name"
              label={NAME[language].last}
              required
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <Form.Group controlId="gender">
              <Form.Label>
                {GENDER[language].label}<span className="required">*</span>
              </Form.Label>
              <Form.Control
                name="gender"
                as="select"
                ref={register}
                isInvalid={errors.gender}
              >
                <option value=""></option>
                <option value="male">{GENDER[language].male}</option>
                <option value="female">{GENDER[language].female}</option>
                <option value="other">{GENDER[language].other}</option>
              </Form.Control>
              {language !== 'spanish' &&
                <Form.Control.Feedback type="invalid">
                  {errors.gender && errors.gender.message}
                </Form.Control.Feedback>
              }
            </Form.Group>
          </Col>

          <Col md={6}>
            <LabelledInput
              name="dob"
              type="DOB"
              label={DOB[language]}
              required
              inputRef={register}
              errors={errors}
            />
          </Col>
          <Col md={6}>
            <LabelledInput
              name="externalId"
              label={`${PATIENT_INFO[language].id} ${PATIENT_INFO[language].id_info}`}
              inputRef={register}
              errors={errors}
            />
          </Col>

          <Col md={12}>
            <h6>
              {NEW_OR_CURRENT_PATIENT[language].label}<span className="required">*</span>
            </h6>
            <Form.Group controlId="patientType">
              {[
                { label: NEW_OR_CURRENT_PATIENT[language].new, value: "Therapist/newPatient" },
                { label: NEW_OR_CURRENT_PATIENT[language].current, value: "Therapist/curPatient" },
              ].map((relationship, index) => (
                <>
                  <Form.Check
                    inline
                    label={relationship.label}
                    value={relationship.value}
                    type="radio"
                    name="patientType"
                    id={`inline-radio-relationship-type-${relationship.value}`}
                    // onChange={onChange}
                    key={index}
                    ref={register}
                  />
                </>
              ))}
              {errors.patientType && (
                <Form.Control.Feedback className="d-block" type="invalid">
                  {NEW_OR_CURRENT_PATIENT[language].error}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <>
              <Form.Group controlId="comments">
                <h6>{COMMENTS[language]}</h6>
                <Form.Control
                  ref={register}
                  type="text"
                  name="comments"
                  isInvalid={errors.comments}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.comments && errors.comments.message}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          </Col>
        </Form.Row>

        <Form.Group>
          {patientTypes && (
            <>
              <h6>
                {PRIMARY_DIAGNOSIS[language].label}
                <span className="required">*</span>
              </h6>
              <Col md={12} className="mt-3">
                <div style={{ display: "flex" }}>
                  {returnedData.map((itemList, i) => {
                    const { category } = itemList;
                    return (
                      <StrokeListSelecter
                        key={category}
                        category={category}
                        message={PRIMARY_DIAGNOSIS[language][category].category}
                        list={itemList.items}
                        index={i}
                        // maxSelectAmount={1}
                        isTherapist={true}
                        selectedList={selectedData[category]}
                        toggleFromSelectedList={toggleFromSelectedList}
                        // selectOne={switchSingleSelection}
                        selectForTherapist={selectForTherapist}
                        addOther={addOther}
                      />
                    );
                  })}
                </div>
              </Col>
            </>
          )}
          {errorMessage && (
            <Form.Control.Feedback style={{ display: "block" }} type="invalid">
              {CHOOSE[language].error}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Col md={12}>
          <SubmitButton disabled={isLoading} type="submit">
            {CONFIRM[language]}
          </SubmitButton>
        </Col>

        {errors.status && (
          <Form.Control.Feedback className="d-block" type="invalid">
            {errors.status && errors.status.message}
          </Form.Control.Feedback>
        )}
      </Form>
    </>
  );
};

export default PatientInfo;
