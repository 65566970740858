const stringifyWeekOfMonth = (data) => {
    let monthArr = new Array(5);
    monthArr[0] = data.firstWeek;
    monthArr[1] = data.secondWeek;
    monthArr[2] = data.thirdWeek;
    monthArr[3] = data.fourthWeek;
    monthArr[4] = data.fifthWeek;
    const monthString = monthArr.toString();
    return monthString;
};

export default stringifyWeekOfMonth;
