import React, { useContext } from "react";
import { PreferredLanguageContext } from "../lib/main-context";
import { NOTIF_PREFS } from "../constants/OnboardingTranslation";

import { Col, Row, Form } from "react-bootstrap";

const WeekInput = ({ label, register, errors, className, disableStatus }) => {
    const { language } = useContext(PreferredLanguageContext);
    return (
        <Row className={className} style={{ padding: "0px 15px 15px 15px" }}>
            <Form.Label
                as="h6"
                style={{ width: "100%", margin: "10px 0px 20px 0px" }}
            >
                {label}
                <div style={{ margin: "1rem 0px 0px 0px" }}>{NOTIF_PREFS[language].week_of_month.label}</div>

            </Form.Label>
            <Form.Group controlId="weekOfMonth"
                style={{ marginBottom: "0.5rem" }}>
                {[
                    { label: NOTIF_PREFS[language].week_of_month.first, name: "firstWeek" },
                    { label: NOTIF_PREFS[language].week_of_month.second, name: "secondWeek" },
                    { label: NOTIF_PREFS[language].week_of_month.third, name: "thirdWeek" },
                    { label: NOTIF_PREFS[language].week_of_month.fourth, name: "fourthWeek" },
                    { label: NOTIF_PREFS[language].week_of_month.fifth, name: "fifthWeek" },
                ].map((week) => (
                    <Form.Check
                        inline
                        label={week.label}
                        type="checkbox"
                        name={week.name}
                        id={week.name}
                        ref={register}
                        disabled={disableStatus}
                        key={week.name}
                        style={{ margin: "0 1rem" }}
                    />
                ))}
            </Form.Group>
            {errors.weekOfMonth && (
                <Form.Control.Feedback style={{ display: "block" }} type="invalid">
                    {errors.weekOfMonth.message}
                </Form.Control.Feedback>
            )}
        </Row>
    );
};

export default WeekInput;
