import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import { GOALS_SETTINGS } from "../../constants/AccountSettingTranslation";

import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import goalSchema from "./validations/goalSchema";

import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";

import { updateGoals, getGoals } from "../../api/TBIRequests";

const SettingGoals = ({ setIsSuccess }) => {
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);

  const [submitting, setSubmitting] = useState(false);
  const { errors, handleSubmit, register, setValue } = useForm({
    resolver: yupResolver(goalSchema),
  });

  useEffect(() => {
    setIsSuccess(undefined);
    getGoals(patient.patientId).then((result) => {
      setValue("firstGoal", result.data[0].first_goal);
      setValue("secondGoal", result.data[0].second_goal);
      setValue("thirdGoal", result.data[0].third_goal);
    });
  }, []);

  const onSubmit = (data) => {
    setSubmitting(true);
    updateGoals({
      patientId: patient.patientId,
      data,
    })
      .then((result) => {
        setIsSuccess(true);
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSuccess(false);
        setSubmitting(false);
      });
  };

  return (
    <Form className="goalForm" onSubmit={handleSubmit(onSubmit)}>
      <Form.Label as="h6" style={{ width: "100%" }}>
        {GOALS_SETTINGS[language].label}
      </Form.Label>
      <LabelledInput
        name="firstGoal"
        label={GOALS_SETTINGS[language].first_goal}
        errors={errors}
        inputRef={register}
        autoFocus
      />
      <LabelledInput
        name="secondGoal"
        label={GOALS_SETTINGS[language].second_goal}
        errors={errors}
        inputRef={register}
      />
      <LabelledInput
        name="thirdGoal"
        label={GOALS_SETTINGS[language].third_goal}
        errors={errors}
        inputRef={register}
      />
      <SubmitButton disabled={submitting} type="submit">
        {GOALS_SETTINGS[language].save}
      </SubmitButton>
    </Form>
  );
};

export default SettingGoals;
