const referralList = {
  name: "Referral",
  children: [
    "Dr Kelli",
    "Brain Injury Group",
    "Provider",
    "Media-Conference",
    "Clinic",
    "none"
  ],
};

export default referralList;