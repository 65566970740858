import * as yup from "yup"

const DescribeStrokeSchema = yup.object().shape({
    stroke_incident_date: yup
        .date()
        .required("Date of Stroke is required")
        .typeError("Enter valid date")
        .max(new Date(), "Enter valid date"),
    describeEvent: yup.string().required("Description required"),
    total_stroke: yup
        .number()
        .integer("Number of Stroke required")
        .required("Number of Stroke required")
        .typeError("Enter a whole number")
        .min(1, "Enter a positive number")
})

export default DescribeStrokeSchema
