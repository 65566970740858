import React, { useState, useContext, useEffect } from "react";
import { PatientContext, ThemeContext, PreferredLanguageContext } from "../../lib/main-context";
import { DAYS_FROM_INJURY } from "../../constants/DashboardTranslation";

import theme from "../../index.scss";
import LoadingSpinner from "../../Components/LoadingSpinner";
import { getDaysFromInjury } from "../../api/TBIRequests";
import ComponentWrapper from "../DashboardComponents/ComponentWrapper";

const DaysFromInjury = () => {
    const { language } = useContext(PreferredLanguageContext);
    const { patient } = useContext(PatientContext);
    const { dark } = useContext(ThemeContext);

    const [daysFromInjury, setDaysFromInjury] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        let isMounted = true; // Use a flag to track whether the component is still mounted.
        const fetchDaysFromInjury = async () => {
            try {
                const response = await getDaysFromInjury(patient.patientId);
                if (isMounted) {
                    // Only update state if the component is still mounted.
                    setDaysFromInjury(response.data);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching days from injury:', error);
                setLoading(false);
            }
        };

        if (patient && patient.patientId) { // Check if patient or patientId has changed
            fetchDaysFromInjury();
        }

        return () => {
            // This cleanup function will be called when the component is unmounted.
            isMounted = false; // Set the flag to false to indicate the component is unmounted.
        };
    }, [patient]);

    return (
        <ComponentWrapper
            style={{
                textAlign: "center",
                width: "90%",
                minHeight: "5rem",
                fontSize: "1.5rem",
                backgroundColor: dark ? theme.darkModePrimary : "#f7bc12",
                color: dark ? theme.darkModeText : "white",
            }}
        >
            {loading ?
                <LoadingSpinner /> :
                daysFromInjury !== null
                    ? `${daysFromInjury} ${DAYS_FROM_INJURY[language].days_from}`
                    : DAYS_FROM_INJURY[language].no_date
            }
        </ComponentWrapper>
    );
};

export default DaysFromInjury;