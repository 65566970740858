import React, { useContext, useState } from "react";
import { PatientContext, InputVisibleContext, ThemeContext, PreferredLanguageContext } from "../../lib/main-context";
import { INVITE_PROVIDER } from "../../constants/DashboardTranslation";

import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import inviteProviderSchema from "./validations/inviteProviderSchema";

import theme from "../../index.scss";
import LabelledInput from "../LabelledInput";
import SubmitButton from "../StyledComponents/SubmitButton";

import {
  sendProviderEmail,
  sendProviderEmail2,
  sendConnectExistingProviderEmail2,
  sendConnectExistingProviderEmail,
  checkProviderEmailExisting,
} from "../../api/helpReq";

const InviteProvider = ({ user, setInputVisible }) => {
  const { dark } = useContext(ThemeContext);
  const { patient } = useContext(PatientContext);
  const { language } = useContext(PreferredLanguageContext);
  const { inputVisible } = useContext(InputVisibleContext);

  const [message, setMessage] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: yupResolver(inviteProviderSchema),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);

    data.providerEmail = data.providerEmail.toLowerCase();

    if (user.userType == "caregiver" || user.userType == "Therapist") {
      checkProviderEmailExisting({ data, patientId: patient.patientId })
        .then((res) => {
          if (res.data.exist == "false") {
            sendProviderEmail({
              data,
              patientId: patient.patientId,
              userType: user.userType,
            })
              .then(() => {
                setMessage(INVITE_PROVIDER[language].messages.sent);
                setInputVisible(false);
              })
              .catch((e) => {
                if (e.response.status === 409) {
                  setError("providerEmail", {
                    type: "alreadyHasProvider",
                    message: INVITE_PROVIDER[language].messages.existing_provider,
                  });
                }
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          } else if (res.data.exist == "true") {
            sendConnectExistingProviderEmail({
              data,
              patientId: patient.patientId,
              userType: user.userType,
            })
              .then(() => {
                setMessage(INVITE_PROVIDER[language].messages.sent);
                setInputVisible(false);
              })
              .catch((e) => {
                if (e.response.status === 409) {
                  setError("providerEmail", {
                    type: "alreadyHasProvider",
                    message: INVITE_PROVIDER[language].messages.existing_provider,
                  });
                } else {
                  // Handle other errors here
                  console.log("An error occurred:", e);
                }
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          } else if (res.data.exist == "connectionExisting") {
            //existing
            setSubmitDisabled(false);
            setMessage(INVITE_PROVIDER[language].messages.existing_connection);
            setInputVisible(false);
          } else if (res.data.exist == "existingOtherTypeAccount") {
            //existing patient or caregiver account.
            setSubmitDisabled(false);
            setMessage(INVITE_PROVIDER[language].messages.other_account_type);
          }
        })
        .catch((e) => {
          console.error("Error checkProviderEmailExisting");
        });
    } else {
      // patient，stroke
      checkProviderEmailExisting({ data, patientId: patient.patientId })
        .then((res) => {
          if (res.data.exist == "false") {
            sendProviderEmail2({ data, patientId: patient.patientId })
              .then(() => {
                setMessage(INVITE_PROVIDER[language].messages.sent);
              })
              .catch((e) => {
                if (e.response.status === 409) {
                  setError("providerEmail", {
                    type: "alreadyHasProvider",
                    message: INVITE_PROVIDER[language].messages.existing_provider,
                  });
                }
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          } else if (res.data.exist == "true") {
            sendConnectExistingProviderEmail2({
              data,
              patientId: patient.patientId,
            })
              .then(() => {
                setMessage(INVITE_PROVIDER[language].messages.sent);
                // setInputVisible(false);
              })
              .catch((e) => {
                if (e.response.status === 409) {
                  setError("providerEmail", {
                    type: "alreadyHasProvider",
                    message: INVITE_PROVIDER[language].messages.existing_provider,
                  });
                } else {
                  // Handle other errors here
                  console.log("An error occurred:", e);
                }
              })
              .finally(() => {
                setSubmitDisabled(false);
              });
          } else if (res.data.exist == "connectionExisting") {
            //existing
            setSubmitDisabled(false);
            setMessage(INVITE_PROVIDER[language].messages.existing_connection);
          } else if (res.data.exist == "existingOtherTypeAccount") {
            //existing patient or caregiver account.
            setSubmitDisabled(false);
            setMessage(INVITE_PROVIDER[language].messages.other_account_type);
          }
        })
        .catch((e) => {
          console.error("Error checkProviderEmailExisting");
        });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <h6
          id="invite-provider-button"
          className="pt-2 pb-2"
          style={{
            width: "75%",
            borderRadius: "20px",
            backgroundColor: dark ? theme.darkModePrimary : "#f7bc12",
            color: dark ? theme.darkModeText : "white",
            textAlign: "center",
            overflowWrap: "break-word",
            cursor: "pointer",
          }}
          onClick={() => setInputVisible(!inputVisible)}
        >
          {INVITE_PROVIDER[language].header[0]} <br />
          {INVITE_PROVIDER[language].header[1]}
        </h6>
      </div>
      {inputVisible && (
        <Row className="p-2">
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="p-2">
                <Col>
                  <LabelledInput
                    required
                    label={INVITE_PROVIDER[language].provider_email}
                    name="providerEmail"
                    type="email"
                    errors={errors}
                    inputRef={register}
                  />
                  <SubmitButton type="submit" disabled={submitDisabled}>
                    {INVITE_PROVIDER[language].button}
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
      {message && (
        <Row className="p-2">
          <Col className="text-center">{message}</Col>
        </Row>
      )}
    </>
  );
};

export default InviteProvider;
