import React, { useState, useEffect, useContext } from "react"
import { PreferredLanguageContext } from "../../lib/main-context";
import {
    FINAL_STEP,
    SALLIE_PAYMENT,
    PLAN,
    PAYMENT,    
    TERMS_AND_CONDITIONS
} from "../../constants/OnboardingTranslation"

import { useHistory } from "react-router-dom"

import { Modal } from "react-bootstrap/"
import SubmitButton from "../StyledComponents/SubmitButton"
import Sallie from "../Sallie"
import PrivacyPage from "../PrivacyPage";
import OnboardingStepHeader from "./OnboardingStepHeader";
import LicenseKeyInput from './LicenseKeyInput';

import { createCheckoutSession, getPaymentRecord } from '../../api/paymentRequest'

const PaymentPage = ({ user }) => {
    const { language } = useContext(PreferredLanguageContext);

    const history = useHistory();
    if (!user) {
        history.push("/signIn");
    }
    const [loading, setLoading] = useState(false);
    const [paid, setPaid] = useState(false)
    const [licenseKeys, setLicenseKeys] = useState([])
    const [verified, setVerified] = useState(false);
    const [invoice, setInvoice] = useState({})
    const [showModal, setShowModal] = useState(false);
    const [timezone, setTimezone] = useState("America/New_York");
    const [licenseEndDate, setLicenseEndDate] = useState('');

    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');
    const userId = params.get('user_id');

    useEffect(() => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (timezone) {
            setTimezone(timezone);
        } else {
            //Intl object doesn't support IE brower, so it returns undefined.
            setTimezone("America/New_York");
        }
    }, []);

    useEffect(() => {
        const checkPaymentRecord = async () => {
            try {
                // Check if both userId and sessionId exist in the URL parameters
                if (userId && sessionId) {
                    if (user.userId === userId) {
                        const res = await getPaymentRecord({ userId, sessionId });
                        if (res.data.invoice) {
                            setInvoice(res.data.invoice)
                            setPaid(true);
                        }
                        else {
                            setPaid(false);
                            console.log("Payment record not found.");
                        }
                        if (res.data.licenseKeys) {
                            setLicenseKeys(res.data.licenseKeys)
                        }
                    } else {
                        console.log("userId error: Current user and paid userId do not match.");
                    }
                } else {
                    const res = await getPaymentRecord({ userId: user.userId });
                    if (res.data.invoice) {
                        setInvoice(res.data.invoice)
                        setPaid(true);
                    } else if (res.data.isVerified) {
                        setVerified(true)
                        setLicenseEndDate(res.data.endDate)
                    } else {
                        setPaid(false);
                        console.log("Payment record not found.");
                    }

                    if (res.data.licenseKeys) {
                        setLicenseKeys(res.data.licenseKeys)
                    }
                }
            } catch (error) {
                console.error("Error fetching payment record:", error);
            }
        };

        checkPaymentRecord();
    }, [sessionId, userId, user]);

    const handleAccept = async () => {
        try {
            setLoading(true);
            const response = await createCheckoutSession({ user, timezone });
            // Redirect the user to the Stripe Checkout page using the returned session URL
            window.location.href = response.data.url;
        } catch (error) {
            console.error('Error checkout:', error);
        } finally {
            setLoading(false);
        }
    };


    const MembershipCard = ({ planName, description, price }) => {
        // Calculate the start and end date
        const startDate = new Date().toLocaleDateString();
        const endDate = new Date();
        endDate.setFullYear(endDate.getFullYear() + 1);

        const descriptionLines = description.split('\n');
        return (
            <div className="membership-card">
                <div className="header">
                    <h2>{planName}</h2>
                </div>
                <ul className="description">
                    {descriptionLines.map((line, index) => (
                        <li key={index}>{line}</li>
                    ))}
                </ul>
                <div >
                    <span className="price">{`${price}/${PLAN[language].month}`}</span>
                    <span>{PLAN[language].minimum}</span>
                </div>
                {!licenseEndDate && <div className="dates">
                    <div className="date-item">
                        <h5>{PLAN[language].from}</h5> <span>{startDate}</span>
                    </div>
                    <div className="date-item">
                        <h5>{PLAN[language].to}</h5> <span>{endDate.toLocaleDateString()}</span>
                    </div>
                </div>}
            </div>
        );
    };

    let SallieText =
        paid
            ? 'Your payment was successful! A digital receipt has been emailed to you.'
            : verified
                ? `You have been verified! Your membership end date is ${licenseEndDate}.`
                : SALLIE_PAYMENT[language].default;

    const membershipPlans = [
        {
            planName: PLAN[language].name,
            description: PLAN[language].description,
            price: "$99",
        },
    ];


    const handleDownloadInvoice = () => {
        window.location.href = invoice.invoice_pdf;
    };

    function getEndDateString(effectiveAtTimestamp) {
        const effectiveDate = new Date(effectiveAtTimestamp * 1000);
        const endYear = effectiveDate.getFullYear() + 1;

        const endDate = new Date(endYear, effectiveDate.getMonth(), effectiveDate.getDate());
        return endDate.toLocaleDateString();
    }

    return (
        <>
            <OnboardingStepHeader text={FINAL_STEP[language]} />
            <Sallie text={SallieText} />
            {!paid && !verified && <div>
                {membershipPlans.map((plan) => (
                    <MembershipCard
                        key={plan.planName}
                        planName={plan.planName}
                        description={plan.description}
                        price={plan.price}
                    />
                ))}
            </div>
            }
            {verified &&
                membershipPlans.map((plan) => (
                    <MembershipCard
                        key={plan.planName}
                        planName={plan.planName}
                        description={plan.description}
                        price={plan.price}
                    />
                ))
            }
            {paid && <>
                <div className="invoice-card">
                    <div className="invoice-container">
                        <h2>Invoice Details</h2>
                        <p>Invoice Number: {invoice.number}</p>
                        <p>Amount Due: ${(invoice.amount_due / 100).toFixed(2)} USD</p>
                        <p>Customer Name: {invoice.customer_name}</p>
                        <p>Customer Email: {invoice.customer_email}</p>
                        <p>Effective Date: {new Date(invoice.effective_at * 1000).toLocaleDateString()}</p>
                        <p>End Date: {getEndDateString(invoice.effective_at)}</p>
                        <p className={`status`}>Status: {invoice.status}</p>
                    </div>
                </div>
                {licenseKeys.length > 0 &&
                    <div className="invoice-card">
                        <div className="invoice-container">
                            <h2>License Keys:</h2>
                            <ul>
                                {licenseKeys.map((key, index) => (
                                    <li key={index}>
                                        <span>{key}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                }
            </>}


            {
                paid ?
                    <div className="d-flex justify-content-end">
                        <SubmitButton onClick={handleDownloadInvoice}>Download PDF</SubmitButton>
                        <SubmitButton onClick={() => history.push("/signIn")}>
                            Go To Dashboard
                        </SubmitButton>
                    </div> : verified ?
                        <SubmitButton onClick={() => history.push("/signIn")}>
                            Go To Dashboard
                        </SubmitButton> :
                        <>
                            <div className="d-flex justify-content-end">
                                <div className="sallie mr-4" style={{ width: '70%' }}>
                                    <h6>{PAYMENT[language].interest}</h6>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <SubmitButton onClick={() => setShowModal(true)}>{PAYMENT[language].submit}</SubmitButton>
                                </div>
                            </div>
                            <LicenseKeyInput user={user} setVerified={setVerified} setLicenseEndDate={setLicenseEndDate} />
                        </>
            }

            {/* Render the Modal component */}
            <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Terms and Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-scroll">
                    <PrivacyPage fromPaymentpage={true} />
                </Modal.Body>
                <Modal.Footer>
                    {!loading && <SubmitButton variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </SubmitButton>}
                    <SubmitButton
                        variant="primary"
                        onClick={handleAccept} // Call the handleAccept function to proceed with payment
                        disabled={loading}
                    >
                        Accept
                    </SubmitButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PaymentPage
