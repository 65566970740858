import React, { useEffect, useState, useContext } from "react";
import { PatientContext, PreferredLanguageContext } from "../../lib/main-context";
import {
    CONDITION_HEADER2,
    SALLIE_CONDITION2,
    FAMILY_HISTORY,
    MEDICATION,
    CHOOSE,
    SAVE_AND_CONTINUE
} from "../../constants/OnboardingTranslation";

// hooks
import { useHistory, useLocation } from "react-router-dom";
import useListSelector from "../../lib/useListSelector";

// components
import Sallie from "../Sallie";
import ListSelector from "../ListSelector";
import SubmitButton from "../StyledComponents/SubmitButton";
import OnboardingStepHeader from "./OnboardingStepHeader";

// api
import { createHistoryMeds, createFamilyHistory, getConditionFormData } from "../../api/TBIRequests";

// lib
import customizeMessages from "../../lib/customizeMessages";
import { Form, Col } from "react-bootstrap";

const RecentCondition2 = ({ setOnboardingPercent, user }) => {
    const { patient } = useContext(PatientContext);
    const { language } = useContext(PreferredLanguageContext);

    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [returnedData, setReturnedData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);

    const history = useHistory();
    const location = useLocation();

    const [fields, setFields] = useState([
        { medication: '', curUsing: '', tolerate: '' }
    ]);

    const handleAddField = () => {
        setFields([...fields, { medication: '', curUsing: '', tolerate: '' }]);
    };

    const handleDeleteField = (index) => {
        const newFields = [...fields];
        newFields.splice(index, 1);
        setFields(newFields);
    };

    const handleChange = (event, index, field) => {
        const newFields = [...fields];
        newFields[index][field] = event.target.value;
        setFields(newFields);
    };

    const handleTypeChange = (event, index) => {
        const newFields = [...fields];
        newFields[index].curUsing = event.target.value;
        setFields(newFields);
    };

    const handleOptionChange = (event, index) => {
        const newFields = [...fields];
        newFields[index].tolerate = event.target.value;
        setFields(newFields);
    };

    const {
        toggleFromSelectedList,
        switchSingleSelection,
        addOther,
        selectedData,
        initialize,
    } = useListSelector({
        setInitialData: setReturnedData,
        initialData: returnedData,
    });

    const onSubmit = async () => {
        const assignedData = {
            familyHistoryOptions: selectedData.familyHistory,
        };
        setSubmitting(true);
        try {
            if (fields.length !== 0) {
                await createHistoryMeds({
                    patientId: patient.patientId,
                    data: fields,
                });
            }
            await createFamilyHistory({
                patientId: patient?.patientId,
                data: assignedData,
            });
            history.push("/laterSymptoms", {
                fromPatientControl: location.state?.fromPatientControl,
            });
        } catch (err) {
            console.error("An error occurred:", err);
            history.push("/oops");
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        let isMounted = true; // Flag to track whether the component is mounted

        const fetchData = async () => {
            try {
                const response = await getConditionFormData();
                const { data } = response;

                // Check if the component is still mounted before updating state
                if (isMounted) {
                    const processedData = data.map(itemList => {
                        const { category, items } = itemList;
                        // Translate each item in the items array
                        const translatedItems = items.map(item =>
                            FAMILY_HISTORY[language].options[item]
                        );
                        return { category, items: translatedItems };
                    });

                    setReturnedData(processedData);
                    initialize(processedData);
                    setLoading(false);
                }
            } catch (err) {
                // Check if the component is still mounted before updating state
                if (isMounted) {
                    history.push("/oops");
                    setLoading(false);
                }
            }
        };

        // Fetch data when the component mounts
        fetchData();

        // Cleanup function to be called when the component unmounts
        return () => {
            isMounted = false;
        };
    }, [language]); // Empty dependency array means this effect runs once when the component mounts


    const getPossibleMessages = () => ({
        mainHeader: {
            tbiPatient: CONDITION_HEADER2[language],
            caregiver: CONDITION_HEADER2[language],
        },
        sallieText: {
            tbiPatient: SALLIE_CONDITION2[language].patient,
            caregiver: SALLIE_CONDITION2[language].caregiver,
        },
        familyHistory: {
            tbiPatient: FAMILY_HISTORY[language].patient,
            caregiver: FAMILY_HISTORY[language].caregiver,
        },
        medications: {
            tbiPatient: MEDICATION[language].patient,
            caregiver: MEDICATION[language].caregiver,
        },
        experiencedTheseSignsText: {
            tbiPatient: "You experienced these signs: ",
            caregiver: "The patient experienced these signs: ",
        },
    });

    const getMessage = customizeMessages({ user, getPossibleMessages });

    // The keys here must match the categories specified on the backend
    const options = {
        familyHistory: {
            message: "familyHistory"
        },
    };

    return (
        <>
            {loading ? (
                "Loading..."
            ) : (
                <div className="d-block">
                    <OnboardingStepHeader text={getMessage("mainHeader")} />
                    <Sallie text={getMessage("sallieText")} />

                    {returnedData.map((itemList, i) => {
                        const { category } = itemList;
                        return (
                            <ListSelector
                                key={category}
                                category={category}
                                message={getMessage(options[category].message)}
                                list={itemList.items}
                                index={i}
                                maxSelectAmount={options[category].responseNumber}
                                selectedList={selectedData[category]}
                                toggleFromSelectedList={toggleFromSelectedList}
                                selectOne={switchSingleSelection}
                                addOther={addOther}
                            />
                        );
                    })}
                    <div className="listSelector">
                        <Form.Label className={'h6-label'}>
                            {getMessage("medications")}
                            {/* <span className="required">*</span> */}
                        </Form.Label>

                        {fields.map((field, index) => (
                            <Form.Row key={index} className="listSelector">
                                <Form.Group as={Col} md={3}>
                                    <Form.Label>{MEDICATION[language].medication_type}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={MEDICATION[language].enter}
                                        value={field.medication}
                                        onChange={(event) => handleChange(event, index, 'medication')}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md={3}>
                                    <Form.Label>{MEDICATION[language].currently_using}</Form.Label>
                                    <Col>
                                        <Form.Check
                                            inline
                                            label={MEDICATION[language].yes}
                                            type="radio"
                                            name={`radio-type-${index}`}
                                            id={`field-${index}-yes`}
                                            value="yes"
                                            checked={field.curUsing === 'yes'}
                                            onChange={(event) => handleTypeChange(event, index)}
                                        />
                                        <Form.Check
                                            inline
                                            label={MEDICATION[language].no}
                                            type="radio"
                                            name={`radio-type-${index}`}
                                            id={`field-${index}-no`}
                                            value="no"
                                            checked={field.curUsing === 'no'}
                                            onChange={(event) => handleTypeChange(event, index)}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Col} md={5}>
                                    <Form.Label>{MEDICATION[language].tolerate}</Form.Label>
                                    <Col>
                                        <Form.Check
                                            inline
                                            label={MEDICATION[language].no_problems}
                                            type="radio"
                                            name={`radio-option-${index}`}
                                            id={`field-${index}-option-a`}
                                            value="No Problems"
                                            checked={field.tolerate === "No Problems"}
                                            onChange={(event) => handleOptionChange(event, index)}
                                        />
                                        <Form.Check
                                            className="pt-2"
                                            inline
                                            label={MEDICATION[language].side_effects}
                                            type="radio"
                                            name={`radio-option-${index}`}
                                            id={`field-${index}-option-b`}
                                            value='Experienced Side Effects'
                                            checked={field.tolerate === 'Experienced Side Effects'}
                                            onChange={(event) => handleOptionChange(event, index)}
                                        />
                                    </Col>
                                </Form.Group>
                                <SubmitButton variant="primary" onClick={() => handleDeleteField(index)}>
                                    X
                                </SubmitButton>
                            </Form.Row>
                        ))}
                        <Form.Row>
                            <SubmitButton variant="primary" onClick={handleAddField}>
                                +
                            </SubmitButton>
                        </Form.Row>
                    </div>

                    {errorMessage && (
                        <Form.Control.Feedback
                            style={{ display: "block" }}
                            type="invalid"
                        >
                            {CHOOSE[language].error}
                        </Form.Control.Feedback>
                    )}
                    <SubmitButton disabled={submitting} onClick={onSubmit} type="submit">
                        {SAVE_AND_CONTINUE[language]} &gt;
                    </SubmitButton>
                </div>
            )}
        </>
    );
};

export default RecentCondition2;
